import { Position } from "@blueprintjs/core";
import { FormikValues, useFormik } from "formik";
import { useCallback, useContext, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { string, array, object } from "yup";
import { onUpdateToast } from "../../../../actions/global/global";
import {
    StyledAlert,
    StyledAlertGlobalContent,
    StyledAlertTypes,
    StyledFloatingInput,
    StyledFloatingInputTypes,
    StyledFloatingPhoneInput,
    StyledFloatingPhoneInputTypes,
    Tag
} from "../../../../components";
import { MPDSelectItem } from "../../../../mpd-library";
import { GroupedDropdownContent } from "../../../../mpd-library/dropdown/components/grouped-dropdown-content/grouped-dropdown-content";
import {
    CustomOptionsComponentProps,
    Dropdown,
    ICreateNewEntityProps,
    Option
} from "../../../../mpd-library/dropdown/dropdown";
import { DropdownTargetClasses } from "src/mpd-library/dropdown/components/target/constants";
import { TCreateNewContactTagParams, createContact, createContactsTag } from "../../../../requests/contactsRequests";
import { getTags } from "src/requests/tagsRequests";
import styles from "./CreateContactsForm.module.scss";
import { useContactsTagsHook } from "../../hooks/useContactsTagsHook";
import { StaticListsDropdown } from "../../contacts-list/components/static-lists-dropdown/StaticListsDropdown";
import * as PhoneInputClasses from "src/mpd-library/floating-phone-input/classes";
import { createTagRequest } from "../../../../requests/tagsRequests";
import { TagDropdownListItem } from "../../../../mpd-library/dropdown/components/tag-dropdown-list-item/TagDropdownListItem";

const classname = "create-contact-form";

const FETCH_TAGS_DATA_PARAMS = {
    feature: "contacts"
};

type CreateContactFormProps = {
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
    onContactWasCretaed: () => void;
};

export const CreateContactForm = ({ isOpen, setIsOpen, onContactWasCretaed }: CreateContactFormProps) => {
    const [createContactProcessing, setCreateContactProcessing] = useState<boolean>(false);
    const dispatch = useDispatch();

    const { selectedTags, onTagItemClick } = useContactsTagsHook();

    const formikValues: FormikValues = useFormik<FormikValues>({
        isInitialValid: false,
        enableReinitialize: true,
        initialValues: {
            first_name: "",
            last_name: "",
            email: "",
            record_type: { value: "resident", label: "Resident" },
            phone_number: "",
            tags: [],
            phone_type: { value: "mobile", label: "Mobile Phone" },
            lists: []
        },
        validationSchema: object().shape(
            {
                first_name: string().required("First name required"),
                last_name: string().required("Last name required"),
                phone_number: string().when("email", {
                    is: (email: string) => !email,
                    then: string().required("Fill in email or phone number")
                }),
                email: string().when("phone_number", {
                    is: (phone_number: string) => !phone_number,
                    then: string().email().required("Fill in email or phone number")
                }),
                tags: array(),
                record_type: object(),
                phone_type: object(),
                lists: array()
            },
            ["email", "phone_number"]
        ),
        // initialTouched: {
        //     queue: true,
        //     inviteMethod: true
        // },
        onSubmit: async (values, { resetForm }) => {
            try {
                setCreateContactProcessing(true);
                const res = await createContact({
                    first_name: values.first_name,
                    last_name: values.last_name,
                    channels: [
                        ...(values.phone_number
                            ? [
                                  {
                                      type: "phone_number",
                                      phone_type: values.phone_type.value,
                                      identifier: values.phone_number
                                  }
                              ]
                            : []),
                        ...(values.email ? [{ type: "email", identifier: values.email }] : [])
                    ],
                    tag_ids: values.tags.map((tag) => tag.id),
                    list_ids: values.lists.map((list) => list.id)
                });

                dispatch(onUpdateToast({ type: "saved", value: "Contact successfully created" }));
                setIsOpen(false);
                onContactWasCretaed();
                resetForm();
            } catch (err) {
                console.log("CREATE ERR >>>>", err);
                dispatch(onUpdateToast({ type: "error", value: "Something get wrong. Try again a bit later" }));
            } finally {
                setCreateContactProcessing(false);
            }
        }
    });

    const { values, handleSubmit, errors, touched, getFieldProps, setFieldValue, resetForm } = formikValues;

    const onCloseAlert = useCallback(() => {
        resetForm();
        setIsOpen(false);
    }, [setIsOpen]);

    const confirmButtonProps = useMemo(
        () => ({
            processing: createContactProcessing,
            onClick: handleSubmit,
            disabled: !formikValues.isValid
        }),
        [createContactProcessing, handleSubmit, formikValues.isValid]
    );

    const onDropdownValueChange = (fieldName: string, option: Option) => {
        setFieldValue(fieldName, option);
    };

    const createNewTagProps = useMemo<ICreateNewEntityProps<TCreateNewContactTagParams>>(
        () => ({
            create: (data) => createTagRequest({ ...data, enable_for_contacts: true }),
            entityName: "tag"
        }),
        [createContactsTag]
    );

    return (
        <StyledAlert
            isOpen={isOpen}
            type={StyledAlertTypes.primary}
            canOutsideClickCancel={true}
            canEscapeKeyCancel={false}
            onCancel={onCloseAlert}
            confirmButtonProps={confirmButtonProps}
            confirmButtonText={"Create"}
            cancelButtonText={"Cancel"}
            className={styles["create-convo-popup"]}
            // canEscapeKeyClose={false}
            fullScreen
            globalContentProps={{
                title: "New Contact",
                hideDescription: true,
                onCancel: onCloseAlert
            }}
        >
            <div className={styles[classname]}>
                <StyledFloatingInput
                    autoFocus
                    hint={"Enter first name..."}
                    placeholder={"FIRST NAME"}
                    floatingType={StyledFloatingInputTypes.primary}
                    error={touched?.first_name && errors?.first_name}
                    {...getFieldProps("first_name")}
                />
                <StyledFloatingInput
                    hint={"Enter last name..."}
                    placeholder={"LAST NAME"}
                    floatingType={StyledFloatingInputTypes.primary}
                    {...getFieldProps("last_name")}
                />
                <StyledFloatingInput
                    hint={"Enter email..."}
                    placeholder={"EMAIL"}
                    floatingType={StyledFloatingInputTypes.primary}
                    error={touched?.email && errors?.email}
                    {...getFieldProps("email")}
                />
                <Dropdown
                    title={"record type"}
                    onStaticOptionClick={(option) => onDropdownValueChange("record_type", option)}
                    staticOptions={[
                        {
                            label: "Resident",
                            value: "resident"
                        },
                        { label: "SMS", value: "sms" }
                    ]}
                    selectedValue={values.record_type}
                    position={Position.BOTTOM}
                    targetClassName={DropdownTargetClasses.FORM_SELECT}
                    ArrowIconComponent
                    {...getFieldProps("record_type")}
                />
                <StyledFloatingPhoneInput
                    floatingType={StyledFloatingPhoneInputTypes.primary}
                    placeholder="PHONE"
                    error={touched?.phone_number && errors?.phone_number}
                    {...getFieldProps("phone_number")}
                    onChange={(value) => setFieldValue("phone_number", value)}
                    className={PhoneInputClasses.FLOATING_PHONE_INPUT_CONTAINER}
                />
                <Dropdown
                    staticOptions={[
                        { label: "Mobile Phone", value: "mobile" },
                        { label: "Landline Phone", value: "landline" }
                    ]}
                    onStaticOptionClick={(option) => onDropdownValueChange("phone_type", option)}
                    selectedValue={values.phone_type}
                    position={Position.BOTTOM}
                    targetClassName={DropdownTargetClasses.FORM_SELECT}
                    title={"Phone type"}
                    ArrowIconComponent
                />
                <Dropdown
                    initialValue={formikValues.initialValues.tags}
                    title={"TAGS"}
                    searchInputPlaceholder={"Search for tags..."}
                    withSearch
                    targetClassName={DropdownTargetClasses.FORM_SELECT}
                    fetchDataRequest={getTags}
                    fetchDataParams={FETCH_TAGS_DATA_PARAMS}
                    entityKey="tags"
                    position={Position.BOTTOM}
                    contentBlockHeight={233}
                    footerProps={{}}
                    SelectedItemComponent={({ item, remove }) => <Tag label={item?.name} onDeleteClick={remove} />}
                    CustomOptionsComponent={({ ...props }: CustomOptionsComponentProps) => (
                        <GroupedDropdownContent
                            {...props}
                            CustomItemComponent={(props) => <TagDropdownListItem {...props} />}
                            firstGroupName="Selected tags"
                            secondGroupName={"Add tags"}
                        />
                    )}
                    {...formikValues.getFieldProps("tags")}
                    onChange={(value) => {
                        formikValues.setFieldValue("tags", value);
                    }}
                    createNewEntityProps={createNewTagProps}
                    ArrowIconComponent
                    values={formikValues.values.tags || []}
                />
                <StaticListsDropdown
                    {...formikValues.getFieldProps("lists")}
                    initialValues={formikValues.initialValues.lists}
                    onChange={(value) => formikValues.setFieldValue("lists", value)}
                    ArrowIconComponent
                />
            </div>
        </StyledAlert>
    );
};
