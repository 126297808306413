import { Position, Text } from "@blueprintjs/core";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router";
import { setBasedOnPermissionsAdmins } from "src/actions/conversations/conversations";
import { onToggleRightPanel, onUpdateToolBar } from "src/actions/global/global";
import { navigate } from "src/actions/global/global-api";
import {
    BreadCrumbs,
    StyledDuoButtons,
    StyledDuoButtonsTypes,
    StyledButtonTypes,
    StyledButton,
    StyledFloatingInput,
    StyledFloatingInputTypes,
    StyledTextArea,
    StyledTextAreaTypes
} from "src/components";
import { PageLoadingSpinner } from "src/components/page-loading-spinner/page-loading-spinner";
import { UseQueues } from "src/containers/conversations/components/chat/hooks/useQueues";
import { getQueueDetails } from "src/requests/conversationsRequests";
import { QueuesContext } from "../queues";
import styles from "./styles.module.scss";
import { FormWasChanged } from "../../../../../components/form-was-changed/FormWasChanged";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ConversationQueue } from "src/store";
import { CustomOptionsComponentProps, Dropdown } from "../../../../../mpd-library/dropdown/dropdown";
import { DropdownTargetClasses } from "src/mpd-library/dropdown/components/target/constants";
import { getAdminsForQueue } from "../../../../../requests/conversationsRequests";
import { Avatar } from "../../../../../components";
import { GroupedDropdownContentSpecial } from "../../../../../mpd-library/dropdown/components/grouped-dropdown-content/grouped-dropdown-content";
import classNames from "classnames";
import { ReactComponent as CloseIcon } from "../../../../../mpd-library/icon/assets/close.svg";
import { DropdownAdminsSelectedItems } from "src/components/dropdown-admins-selected-items/DropdownAdminsSelectedItems";

const classname = "queue-add";

type QueueDetailsRouterParam = {
    id: string;
};
type QueueItemProps = {
    queue: ConversationQueue;
};

const transformAdminObject = (admin) => {
    const adminData = admin.admin ? { ...admin, ...admin.admin } : admin;
    return {
        disabled: adminData.based_on_permissions,
        id: adminData.id,
        label: `${adminData.first_name} ${adminData.last_name}`,
        checked: adminData.has_access,
        checkedUpdated: adminData.has_access,
        children: (
            <div className={styles["admin-item"]}>
                <Avatar
                    avatarUrl={adminData.avatar || adminData.profile_pic?.url}
                    user={adminData}
                    className={styles[`${classname}-avatar-wrapper_list-item`]}
                    size={24}
                />
                <div className={styles["admin-item-left"]}>
                    <span>
                        {`${adminData.first_name} ${adminData.last_name}`}
                        <span className={classNames(styles["invited"], styles["based-on-permissions"])}>
                            {adminData?.status === "invited" && "(Invited)"}
                        </span>
                    </span>
                    {adminData.based_on_permissions && (
                        <span className={styles["based-on-permissions"]}>Based on permissions</span>
                    )}
                </div>
            </div>
        ),
        ...adminData
    };
};

export const QueuesAdd = ({ queue }: QueueItemProps) => {
    const history = useHistory();
    const { createQueue, updateQueue } = useContext<UseQueues>(QueuesContext);
    const isEditCase = history.location.pathname.includes("edit");
    const isDetailsCase = history.location.pathname.includes("details");
    const { id: idFromUrl } = useParams<QueueDetailsRouterParam>();
    const [queueDescription, setQueueDescription] = useState("");
    const [adminsListRefresh, setAdminsListRefresh] = useState<boolean>(false);

    const dispatch = useDispatch();
    const [createQueueProcessing, setCreateQueueProcessing] = useState(false);
    const [getingQueueDetails, setGettingQueuesDetails] = useState(true);
    const [checkedAdmins, setCheckedAdmins] = useState({});
    const [selectedQueueDetails, setSelectedQueueDetails] = useState({});
    const [adminsCount, setAdminsCount] = useState<number>(0);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            newQueueName: selectedQueueDetails?.name,
            admins_diff: {
                add_admin_ids: {},
                remove_admin_ids: {}
            },

            queueDescription: selectedQueueDetails?.description
        },

        validationSchema: Yup.object({
            newQueueName: Yup.string().required("Can't be blank"),
            queueDescription: Yup.string().nullable()
        }),
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            await onSaveClick(values);
            setAdminsListRefresh(true);
            setSubmitting(false);
        }
    });

    const { handleChange, handleSubmit, touched, errors, values, setFieldValue, getFieldProps } = formik;

    const getfunction = () => {
        const onGetQueueDetails = async () => {
            try {
                setGettingQueuesDetails(true);
                const {
                    data: { queue }
                } = await getQueueDetails(idFromUrl);
                setSelectedQueueDetails(queue);
                setAdminsCount(queue.admins_count);
            } finally {
                setGettingQueuesDetails(false);
            }
        };

        idFromUrl
            ? onGetQueueDetails()
            : (async () => {
                  try {
                      setGettingQueuesDetails(true);
                      const res = await getQueuesAdmins({});
                      setSelectedQueueDetails({
                          ...selectedQueueDetails,
                          admins: res.data.admins
                      });
                      setAdminsCount(res.data.count);
                  } catch (err) {
                      console.log("ERR >>>>>", err);
                  } finally {
                      setGettingQueuesDetails(false);
                  }
              })();

        return () => {
            setSelectedQueueDetails(null);
            dispatch(setBasedOnPermissionsAdmins([]));
        };
    };

    useEffect(() => {
        if (adminsListRefresh) {
            setAdminsListRefresh(false);
        }
    }, [adminsListRefresh]);

    useEffect(() => {
        getfunction();
    }, []);

    const onSaveClick = useCallback(
        async (values) => {
            try {
                setCreateQueueProcessing(true);

                const data = {
                    name: values.newQueueName,
                    add_admin_ids: Object.keys(values.admins_diff.add_admin_ids).filter(
                        (key) => !!values.admins_diff.add_admin_ids[key]
                    ),
                    remove_admin_ids: Object.keys(values.admins_diff.remove_admin_ids).filter(
                        (key) => !!values.admins_diff.remove_admin_ids[key]
                    ),
                    description: values.queueDescription
                };

                let res;
                if (isDetailsCase) {
                    res = await updateQueue(idFromUrl, data);
                } else {
                    res = await createQueue(data);
                }

                setSelectedQueueDetails(res.data.queue);
                formik.setFieldValue("admins_diff", {
                    add_admin_ids: {},
                    remove_admin_ids: {}
                });
            } catch (err) {
                console.log("ERR >>>", err);
            } finally {
                setCreateQueueProcessing(false);
            }
        },
        [values]
    );

    const renderTopToolbarDuoButtons = useMemo(() => {
        return (
            !isDetailsCase && (
                <StyledButton
                    className="actions-button"
                    type={StyledButtonTypes.primarySimple}
                    IconComponent={CloseIcon}
                    onClick={() => history.push("/account-settings/queues")}
                />
            )
        );
    }, [checkedAdmins, selectedQueueDetails, queueDescription, createQueueProcessing, formik]);

    useEffect(() => {
        const breadCrumb = {
            text: isEditCase || isDetailsCase ? selectedQueueDetails?.name : "New Queue"
        };

        const topBarProps = {
            leftElement: (
                <BreadCrumbs
                    breadCrumbsList={[{ text: "Queues", pathname: "/account-settings/queues" }, breadCrumb]}
                    history={history}
                    toggleButtonProps={{
                        onToggle: () => dispatch(onToggleRightPanel()),
                        onClose: () => dispatch(navigate({ pathname: "/account-settings", history }))
                    }}
                />
            ),
            rightElement: selectedQueueDetails ? renderTopToolbarDuoButtons : renderTopToolbarDuoButtons
        };
        dispatch(onUpdateToolBar(topBarProps));
    }, [values.newQueueName, values.checkedAdmins, selectedQueueDetails, values.queueDescription]);

    const getQueuesAdmins = async (params) => {
        const res = await getAdminsForQueue(idFromUrl, { ...params, with_candidates: true });

        const transformed = {
            ...res,
            data: {
                ...res.data,
                admins: res.data.admins.map((admin) => transformAdminObject(admin))
            }
        };
        return transformed;
    };

    const onAgentSelecDeselct = (agent, checked) => {
        if (agent.checked) {
            formik.setFieldValue("admins_diff.remove_admin_ids", {
                ...formik.values.admins_diff.remove_admin_ids,
                [agent.id]: !checked
            });
            setAdminsCount(!checked ? adminsCount - 1 : adminsCount + 1);
        } else {
            formik.setFieldValue("admins_diff.add_admin_ids", {
                ...formik.values.admins_diff.add_admin_ids,
                [agent.id]: checked
            });
            setAdminsCount(checked ? adminsCount + 1 : adminsCount - 1);
        }
    };

    const adminsDropdownValue = useMemo(() => {
        return [
            ...(selectedQueueDetails?.admins?.slice(0, 5).map((admin) => {
                return {
                    ...admin,
                    ...admin.admin,
                    id: admin.admin.id
                };
            }) || [])
        ];
    }, [selectedQueueDetails?.admins]);

    if (getingQueueDetails) {
        return <PageLoadingSpinner />;
    }

    return (
        <div className={styles[`${classname}-main-container`]}>
            <form onSubmit={handleSubmit}>
                <div className={styles[`${classname}`]}>
                    <Text className={styles[`${classname}-title`]}>QUEUE DETAILS</Text>
                    <div className={styles[`${classname}-name-and-admins`]}>
                        <div className={styles[`${classname}-input-wrapper`]}>
                            <StyledFloatingInput
                                autoCapitalize="none"
                                autoCorrect="none"
                                floatingType={StyledFloatingInputTypes.primary}
                                onChange={handleChange}
                                placeholder="QUEUE NAME"
                                tabIndex={1}
                                isMaxLengthRequire={false}
                                showErrorText={true}
                                disabled={selectedQueueDetails?.is_default}
                                showErrorText={true}
                                error={touched.newQueueName && errors.newQueueName}
                                {...getFieldProps("newQueueName")}
                            />
                        </div>
                        <div className={styles[`${classname}-input-wrapper`]}>
                            <Dropdown
                                title={"ADD AGENTS"}
                                values={adminsDropdownValue || []}
                                // initialEntites={selectedQueueDetails?.admins?.map((admin) => {
                                //     const transformedAdmin = transformAdminObject(admin);
                                //     return transformedAdmin;
                                // })}
                                refreshList={adminsListRefresh}
                                diffDisabled
                                SelectedItemComponent={(props) => (
                                    <DropdownAdminsSelectedItems {...props} count={adminsCount} />
                                )}
                                fetchDataRequest={getQueuesAdmins}
                                entityKey={"admins"}
                                targetClassName={DropdownTargetClasses.FORM_SELECT}
                                withSearch
                                position={Position.BOTTOM}
                                matchTargetWidth
                                contentBlockHeight={400}
                                onChange={onAgentSelecDeselct}
                                CustomOptionsComponent={({ ...props }: CustomOptionsComponentProps) => (
                                    <GroupedDropdownContentSpecial
                                        {...props}
                                        firstGroupName="agents with access"
                                        secondGroupName={"Add new agents"}
                                    />
                                )}
                                ArrowIconComponent
                            />
                        </div>
                    </div>
                    <div className={styles[`${classname}-text-area-wrapper`]}>
                        <StyledTextArea
                            className={styles[`${classname}-desc-textarea`]}
                            autoCapitalize="none"
                            autoCorrect="none"
                            type={StyledTextAreaTypes.primaryStatic}
                            onChange={handleChange}
                            placeholder="description"
                            tabIndex={1}
                            rows={5}
                            isMaxLengthRequire={false}
                            value={queueDescription}
                            showErrorText={true}
                            disabled={selectedQueueDetails?.is_default}
                            error={touched.queueDescription && errors.queueDescription}
                            {...getFieldProps("queueDescription")}
                        />
                    </div>
                </div>
            </form>
            <FormWasChanged formik={formik} />
            {/* {!isDetailsCase && (
                <StyledDuoButtons
                    className={styles[`${classname}-footer`]}
                    className={styles[`${classname}-footer`]}
                    firstButtonClick={() => {
                        history.push("/account-settings/queues");
                        setSelectedQueueDetails(null);
                    }}
                    secondButtonClick={() => onSaveClick(formik?.values)}
                    firstButtonText="Cancel"
                    secondButtonText="Save"
                    secondButtonProps={{ processing: createQueueProcessing }}
                    type={StyledDuoButtonsTypes.primary}
                />
            )} */}
        </div>
    );
};
