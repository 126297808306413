import { useMemo } from "react";
import { FormikValues } from "formik";
import { ConversationsRoutingSettings } from "src/components/conversations-routing-settings/conversations-routing-settings";

type SmartLinksRoutingSettingsProps = {
    formikValues: FormikValues;
};

export const SmartLinksRoutingSettings = ({ formikValues }: SmartLinksRoutingSettingsProps) => {
    const { setFieldValue, values, initialValues }: FormikValues = formikValues;

    const onSetSettings = (value: string, key: string) => {
        setFieldValue(key, value);
    };

    const routingSettings = useMemo(() => ({ tags: values.tags, admin: values.admin, queue: values.queue }), [values]);

    return (
        <ConversationsRoutingSettings
            setSelectedSettings={onSetSettings}
            routingSettings={routingSettings}
            routingSettingsInitial={initialValues}
        />
    );
};
