// tslint:disable jsx-no-lambda
import classNames from "classnames";
import React, { useCallback } from "react";

import { Classes } from "src/mpd-library";

import { formatPhoneNumber } from "src/actions";

import { IProps } from "@blueprintjs/core";

import {
    StyledFloatingInput,
    StyledFloatingInputTypes,
    ImageUpload,
    SettingsTitle,
    StyledSelect,
    StyledSelectTypes
} from "src/components";

import "./styles.scss";
import { AccountOrganzationDetails } from "src/store";
import { FormWasChanged } from "../../../../../components/form-was-changed/FormWasChanged";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ReactComponent as PlusWhiteIcon } from "../../../../../mpd-library/icon/assets/plus-white.svg";

export interface IAccountDetailsSettingsEditProps extends IProps {
    currentPlan: string | undefined;
    details: AccountOrganzationDetails;
    onChangeInput: (event: React.SyntheticEvent, type: string) => void;
    onUploadImage: (event: React.SyntheticEvent) => void;
    onSave: (event: Object, type: string) => void;
}

export const AccountDetailsSettingsEdit: React.FunctionComponent<IAccountDetailsSettingsEditProps> = (props) => {
    const { details, className, onUploadImage, onChangeInput, onSave, currentPlan } = props;

    const formik = useFormik({
        initialValues: {
            accountName: details?.accountName,
            totalEmployees: details?.totalEmployees,
            populationServed: details?.populationServed,
            primaryAddress: details?.primaryAddress,
            city: details?.city,
            state: details?.state,
            postalCode: details?.postalCode,
            country: details?.country,
            lat: details?.center?.lat,
            lng: details?.center?.lng,
            teamAgentFirstName: details?.teamAgentFirstName,
            teamAgentLastName: details?.teamAgentLastName
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            accountName: Yup.string().required("Can't be blank"),
            totalEmployees: Yup.string().required("Can't be blank"),
            populationServed: Yup.string().required("Can't be blank"),
            primaryAddress: Yup.string().required("Can't be blank"),
            city: Yup.string().required("Can't be blank"),
            state: Yup.string().required("Can't be blank"),
            postalCode: Yup.string().required("Can't be blank"),
            country: Yup.string().required("Can't be blank"),
            lat: Yup.string().required("Can't be blank"),
            lng: Yup.string().required("Can't be blank"),
            teamAgentFirstName: Yup.string().required("Can't be blank"),
            teamAgentLastName: Yup.string().required("Can't be blank")
        }),
        onSubmit: async (values, { setSubmitting }) => {
            await onSave(values, "edit");
            setSubmitting(true);
        }
    });

    const { handleSubmit, touched, errors, getFieldProps } = formik;

    const classes = classNames("organization-settings-detail-edit", className);
    return (
        <div className={classNames(classes + "-main-container")}>
            <form onSubmit={handleSubmit}>
                <div className={classNames(Classes.CONTAINER_PADDING, Classes.ROW, Classes.RESPONSIVE)}>
                    <div className={Classes.COLUMN}>
                        <div className={classes + "-details-info"}>
                            <SettingsTitle title="Basic Info" />
                            <div
                                className={classNames(
                                    classes + "-details-info-item-container",
                                    Classes.ASSIGN_BLOCK_PADDING
                                )}
                            >
                                <StyledFloatingInput
                                    autoCapitalize="none"
                                    autoCorrect="none"
                                    floatingType={StyledFloatingInputTypes.primary}
                                    value={details.accountName}
                                    onChange={(e) => onChangeInput(e, "accountName")}
                                    placeholder="ACCOUNT NAME"
                                    tabIndex={1}
                                    error={touched.accountName && errors.accountName}
                                    {...getFieldProps("accountName")}
                                />
                                <div className={classNames(Classes.ROW, Classes.RESPONSIVE)}>
                                    <StyledFloatingInput
                                        autoCapitalize="none"
                                        autoCorrect="none"
                                        floatingType={StyledFloatingInputTypes.primary}
                                        value={details.totalEmployees || ""}
                                        onChange={(e) => onChangeInput(e, "totalEmployees")}
                                        placeholder="TOTAL EMPLOYEES"
                                        tabIndex={1}
                                        error={touched.totalEmployees && errors.totalEmployees}
                                        {...getFieldProps("totalEmployees")}
                                    />
                                    <StyledFloatingInput
                                        autoCapitalize="none"
                                        autoCorrect="none"
                                        floatingType={StyledFloatingInputTypes.primary}
                                        value={details.populationServed || ""}
                                        onChange={(e) => onChangeInput(e, "populationServed")}
                                        placeholder="POPULATION SERVED"
                                        tabIndex={1}
                                        error={touched.populationServed && errors.populationServed}
                                        {...getFieldProps("populationServed")}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={classes + "-details-info"}>
                            <SettingsTitle title={`ADDRESS & MAP CENTER`} />
                            <div
                                className={classNames(
                                    classes + "-details-info-item-container",
                                    Classes.ASSIGN_BLOCK_PADDING
                                )}
                            >
                                <StyledFloatingInput
                                    autoCapitalize="none"
                                    autoCorrect="none"
                                    floatingType={StyledFloatingInputTypes.primary}
                                    value={details.primaryAddress}
                                    onChange={(e) => onChangeInput(e, "primaryAddress")}
                                    placeholder="ADDRESS"
                                    tabIndex={1}
                                    error={touched.primaryAddress && errors.primaryAddress}
                                    {...getFieldProps("primaryAddress")}
                                />
                                {/* <StyledFloatingInput
                    autoCapitalize="none"
                    autoCorrect="none"
                    floatingType={StyledFloatingInputTypes.primary}
                    value={details.secondaryAddress}
                    onChange={e => onChangeInput(e, 'secondaryAddress')}
                    placeholder="ADDRESS LINE 2"
                    tabIndex={1}
                /> */}
                                <div className={classNames(Classes.ROW, Classes.RESPONSIVE)}>
                                    <StyledFloatingInput
                                        autoCapitalize="none"
                                        autoCorrect="none"
                                        floatingType={StyledFloatingInputTypes.primary}
                                        value={details.city}
                                        onChange={(e) => onChangeInput(e, "city")}
                                        placeholder="CITY"
                                        tabIndex={1}
                                        error={touched.city && errors.city}
                                        {...getFieldProps("city")}
                                    />
                                    <StyledFloatingInput
                                        autoCapitalize="none"
                                        autoCorrect="none"
                                        floatingType={StyledFloatingInputTypes.primary}
                                        value={details.state}
                                        onChange={(e) => onChangeInput(e, "state")}
                                        placeholder="STATE/PROVINCE"
                                        tabIndex={1}
                                        error={touched.state && errors.state}
                                        {...getFieldProps("state")}
                                    />
                                </div>
                                <div className={classNames(Classes.ROW, Classes.RESPONSIVE)}>
                                    <StyledFloatingInput
                                        autoCapitalize="none"
                                        autoCorrect="none"
                                        floatingType={StyledFloatingInputTypes.primary}
                                        value={details.postalCode}
                                        onChange={(e) => onChangeInput(e, "postalCode")}
                                        placeholder="ZIP/POSTAL CODE"
                                        tabIndex={1}
                                        error={touched.postalCode && errors.postalCode}
                                        {...getFieldProps("postalCode")}
                                    />
                                    <StyledFloatingInput
                                        autoCapitalize="none"
                                        autoCorrect="none"
                                        floatingType={StyledFloatingInputTypes.primary}
                                        value={details.country}
                                        onChange={(e) => onChangeInput(e, "country")}
                                        placeholder="COUNTRY"
                                        tabIndex={1}
                                        error={touched.country && errors.country}
                                        {...getFieldProps("country")}
                                    />
                                </div>
                                <div className={classNames(Classes.ROW, Classes.RESPONSIVE)}>
                                    <StyledFloatingInput
                                        autoCapitalize="none"
                                        autoCorrect="none"
                                        floatingType={StyledFloatingInputTypes.primary}
                                        value={details.center?.lat || ""}
                                        onChange={(e) => onChangeInput(e, "lat")}
                                        placeholder="Latitude"
                                        tabIndex={1}
                                        error={touched.lat && errors.lat}
                                        {...getFieldProps("lat")}
                                    />
                                    <StyledFloatingInput
                                        autoCapitalize="none"
                                        autoCorrect="none"
                                        floatingType={StyledFloatingInputTypes.primary}
                                        value={details.center?.lng || ""}
                                        onChange={(e) => onChangeInput(e, "lng")}
                                        placeholder="Longitude"
                                        tabIndex={1}
                                        error={touched.lng && errors.lng}
                                        {...getFieldProps("lng")}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={Classes.COLUMN}>
                        <div className={classes + "-details-info"}>
                            <SettingsTitle title="team agent" />
                            <div
                                className={classNames(
                                    classes + "-details-info-item-container",
                                    Classes.ASSIGN_BLOCK_PADDING
                                )}
                            >
                                <div
                                    className={classNames(
                                        classes + "-account-owner-names",
                                        Classes.ROW,
                                        Classes.RESPONSIVE
                                    )}
                                >
                                    <StyledFloatingInput
                                        autoCapitalize="none"
                                        autoCorrect="none"
                                        floatingType={StyledFloatingInputTypes.primary}
                                        value={details.teamAgentFirstName}
                                        onChange={(e) => onChangeInput(e, "teamAgentFirstName")}
                                        placeholder="FIRST NAME"
                                        tabIndex={1}
                                        error={touched.teamAgentFirstName && errors.teamAgentFirstName}
                                        {...getFieldProps("teamAgentFirstName")}
                                    />
                                    <StyledFloatingInput
                                        autoCapitalize="none"
                                        autoCorrect="none"
                                        floatingType={StyledFloatingInputTypes.primary}
                                        value={details.teamAgentLastName}
                                        onChange={(e) => onChangeInput(e, "teamAgentLastName")}
                                        placeholder="LAST NAME"
                                        tabIndex={1}
                                        error={touched.teamAgentLastName && errors.teamAgentLastName}
                                        {...getFieldProps("teamAgentLastName")}
                                    />
                                </div>
                                <StyledFloatingInput
                                    autoCapitalize="none"
                                    autoCorrect="none"
                                    floatingType={StyledFloatingInputTypes.primary}
                                    value={details.teamAgentEmail}
                                    disabled={true}
                                    onChange={(e) => onChangeInput(e, "teamAgentEmail")}
                                    placeholder="EMAIL"
                                    tabIndex={1}
                                />
                                <StyledFloatingInput
                                    autoCapitalize="none"
                                    autoCorrect="none"
                                    floatingType={StyledFloatingInputTypes.primary}
                                    value={formatPhoneNumber(details.teamAgentPhone)}
                                    disabled={true}
                                    onChange={(e) => onChangeInput(e, "teamAgentPhone")}
                                    placeholder="PHONE"
                                    tabIndex={1}
                                />
                            </div>
                        </div>
                        {currentPlan !== "essential" && (
                            <div className={classes + "-details-info"}>
                                <SettingsTitle title="localization" />
                                <div
                                    className={classNames(
                                        classes + "-details-info-item-container",
                                        Classes.ASSIGN_BLOCK_PADDING
                                    )}
                                >
                                    <StyledSelect
                                        className={classes + "-styled-select"}
                                        placeholder="TIME ZONE"
                                        options={["(GMT-05:00) Central Time (US &quot; Canada)"]}
                                        // value={'(GMT-05:00) Central Time (US &quot; Canada)'}
                                        type={StyledSelectTypes.solid}
                                    />
                                    <div className={classNames(Classes.ROW, Classes.RESPONSIVE)}>
                                        <StyledSelect
                                            className={classes + "-styled-select"}
                                            placeholder="TIME FORMAT"
                                            options={["12 hour clock", "24 hour clock"]}
                                            // value={'12 hour clock'}
                                            type={StyledSelectTypes.solid}
                                        />
                                        <StyledSelect
                                            className={classes + "-styled-select"}
                                            placeholder="LANGUAGE"
                                            options={["English", "Spanish"]}
                                            // value={'English'}
                                            type={StyledSelectTypes.solid}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                        {currentPlan !== "essential" && (
                            <div className={classes + "-details-info"}>
                                <SettingsTitle title="subdomain" />
                                <div
                                    className={classNames(
                                        classes + "-details-info-item-container",
                                        Classes.ASSIGN_BLOCK_PADDING
                                    )}
                                >
                                    <StyledFloatingInput
                                        autoCapitalize="none"
                                        autoCorrect="none"
                                        floatingType={StyledFloatingInputTypes.primary}
                                        value={details.url}
                                        onChange={(e) => onChangeInput(e, "url")}
                                        placeholder="URL"
                                        tabIndex={1}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </form>
            {<FormWasChanged formik={formik} />}
        </div>
    );
};

export default AccountDetailsSettingsEdit;
