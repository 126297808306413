import classNames from "classnames";
import React from "react";

import { Checkbox, ICheckboxProps } from "@blueprintjs/core";

import "./styles.scss";

export interface IStyledCheckboxProps extends ICheckboxProps {
    checkboxType?: string;
}

export const StyledCheckbox: React.FunctionComponent<IStyledCheckboxProps> = (props) => {
    const { className, checkboxType, ...checkboxProps } = props;

    const classes = classNames("styled-checkbox", checkboxType, className, "ahahah");

    return (
        <div onClick={(e) => e.stopPropagation()} className={"stop-propagation-block"}>
            <Checkbox
                className={classes}
                {...checkboxProps}
                onClick={(e) => {
                    e.stopPropagation();
                    checkboxProps.onClick && checkboxProps.onClick(e);
                }}
            />
        </div>
    );
};
