import {
    ComponentType,
    FunctionComponent,
    InputHTMLAttributes,
    SVGProps,
    forwardRef,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState
} from "react";
import classNames from "classnames";
import {
    DefaultPopover2TargetHTMLProps,
    Popover2ClickTargetHandlers,
    Popover2HoverTargetHandlers,
    Popover2TargetProps
} from "@blueprintjs/popover2";
import { Classes, Icon, Position } from "@blueprintjs/core";
import { MPDIcon } from "src/mpd-library/icon/icon";
import { ReactComponent as SearchIcon } from "src/mpd-library/icon/assets/search.svg";
import { ReactComponent as DropdownArrowBlackIcon } from "src/mpd-library/icon/assets/down-arrow.svg";

import styles from "./Target.module.scss";
import { Option } from "../../types";
import { Tooltip } from "src/components/tooltip/tooltip";

interface DropdownTargetCommon {
    selectedItems?: Array<Option>;
    onDelete?: any; // should be removed
    error?: string;
    title?: string;
    targetClassName: string;
    disabled?: boolean;
    withSearch?: boolean; // Use 'true' here to trigger the condition
    LeftIconComponent?: FunctionComponent;
    leftLabel?: string; // find another solution. Better to remove
    selectedValue: any;
    CustomTarget?: ComponentType<Popover2TargetProps>;
    ArrowIconComponent?: FunctionComponent<SVGProps<SVGSVGElement>> | boolean;
    SelectedItemComponent: ComponentType;
    removeSelectedItem: (option: Option) => void;
}

type DropdownTargetPropsBase = Popover2TargetProps &
    Popover2HoverTargetHandlers<DefaultPopover2TargetHTMLProps> &
    Popover2ClickTargetHandlers<Popover2ClickTargetHandlers> &
    DropdownTargetCommon;

// Use a conditional type to include search-related properties if 'withSearch' is true
type DropdownTargetProps = DropdownTargetCommon["withSearch"] extends true
    ? DropdownTargetPropsBase & {
          searchValue?: string;
          searchInputPlaceholder?: string;
          updateEntites: UseDataFetch;
          setSearchValue: (value: string) => void;
      }
    : DropdownTargetPropsBase;

export const DropdownTarget = forwardRef((targetProps: DropdownTargetProps, ref) => {
    const searchInputRef = useRef(null);
    const {
        id,
        title,
        isOpen,
        CustomTarget,
        disabled,
        targetClassName,
        error,
        selectedValue,
        LeftIconComponent,
        withSearch,
        leftLabel, // find another solution. Better to remove,
        selectedItems,
        onDelete, // should be removed,
        searchInputPlaceholder,
        ArrowIconComponent,
        searchValue,
        SelectedItemComponent,
        setSearchValue,
        onChangeValue,
        updateEntites
    } = targetProps;
    const [targetHovered, setTargetHovered] = useState<boolean>(false);

    const onHover = () => {
        setTargetHovered(true);
    };

    const onUnhover = () => {
        setTargetHovered(false);
    };

    const onSearchInputChange = (e: InputHTMLAttributes<HTMLInputElement>) => {
        const { value } = e.target;
        setSearchValue(value);
    };

    useEffect(() => {
        if (searchInputRef.current) {
            if (isOpen) {
                searchInputRef.current?.focus();
            } else {
                searchInputRef.current?.blur();
            }
        }
    }, [isOpen]);

    const onSearchInputKeyUp = useCallback(
        (event: KeyboardEvent) => {
            if (event.key === "Backspace" && searchValue?.length === 0) {
                onChangeValue?.(selectedItems?.slice(0, -1));
            }
        },
        [searchValue, selectedItems]
    );

    const removeItem = (entity) => {
        onChangeValue?.(selectedItems?.filter((alreadySelectedEntity) => alreadySelectedEntity.id !== entity.id));
    };

    const showLabel = useMemo(() => {
        return (
            (typeof selectedItems !== "undefined" && !selectedItems?.length) || (selectedValue && selectedValue?.label)
        );
    }, [targetClassName, selectedValue, selectedItems?.length]);

    if (CustomTarget) {
        return (
            <div
                ref={ref}
                {...targetProps}
                className={classNames(
                    Classes.POPOVER_TARGET,
                    styles["dropdown-target"],
                    isOpen && Classes.ACTIVE,
                    disabled && styles["disabled"]
                )}
            >
                <CustomTarget />
            </div>
        );
    }
    return (
        <div
            {...targetProps}
            ref={ref}
            className={classNames(
                Classes.POPOVER_TARGET,
                styles["dropdown-target"],
                isOpen && Classes.ACTIVE,
                targetClassName,
                error && styles["error"],
                disabled && styles["disabled"],
                selectedValue && styles["with-selected-value"],
                selectedValue?.isNotSelected && styles["is-not-selected"]
            )}
            onMouseEnter={onHover}
            onMouseLeave={onUnhover}
        >
            {LeftIconComponent && (
                <MPDIcon IconComponent={LeftIconComponent || SearchIcon} className={classNames(styles["left-icon"])} />
            )}

            {withSearch && selectedItems?.length === 0 && (
                <MPDIcon IconComponent={LeftIconComponent || SearchIcon} className={classNames(styles["left-icon"])} />
            )}
            {!!title && <span className={styles["title"]}>{title}</span>}
            {leftLabel && <span className={classNames(styles["label"], styles["left-label"])}>{leftLabel}</span>}
            {showLabel && (
                <span className={classNames(styles["label"], onDelete && targetHovered && styles["gradient"])}>
                    {selectedValue?.label}
                </span>
            )}
            {(!!withSearch || !!selectedItems?.length) && (
                <div className={styles["search-and-selected"]}>
                    {!!selectedItems?.length && SelectedItemComponent && (
                        <>
                            {selectedItems.map((item, index, array) => {
                                // const item = selectedItems?.byId[itemId];
                                return (
                                    <SelectedItemComponent
                                        remove={() => removeItem(item)}
                                        item={item}
                                        index={index}
                                        count={array.length}
                                    />
                                );
                            })}
                        </>
                    )}

                    {withSearch && (
                        <input
                            ref={searchInputRef}
                            placeholder={selectedItems?.length === 0 && searchInputPlaceholder}
                            className={styles["search-input"]}
                            value={searchValue}
                            onChange={onSearchInputChange}
                            onBlur={() => {
                                isOpen && searchInputRef.current.focus();
                            }}
                            onKeyDown={onSearchInputKeyUp}
                        />
                    )}
                </div>
            )}
            {onDelete && targetHovered && (
                <Tooltip
                    className={styles["remove-dropdown-button-tooltip"]}
                    position={Position.BOTTOM}
                    content={"Remove Filter"}
                    tootltipTarget={
                        <div
                            className={styles["remove-dropdown-button"]}
                            onClick={(event) => {
                                event.stopPropagation();
                                onDelete();
                            }}
                        >
                            <Icon icon={"cross"} />
                        </div>
                    }
                />
            )}
            {ArrowIconComponent && (
                <MPDIcon
                    IconComponent={
                        typeof ArrowIconComponent === "boolean" ? DropdownArrowBlackIcon : ArrowIconComponent
                    }
                    className={classNames(styles["arrow-icon"])}
                />
            )}
        </div>
    );
});
