import { FC, useContext, useMemo, createContext, useRef, useEffect, useLayoutEffect, useState, memo } from "react";
import { Classes } from "@blueprintjs/core";
import { MPDSelectItem } from "../../../select-item";
import styles from "./GroupedDropdownContent.module.scss";
import { CustomOptionsComponentProps as CustomOptionsComponentCommonProps } from "../../dropdown";
import groupBy from "lodash/groupBy";
import classNames from "classnames";
import { Option } from "../../types";
import { StyledButton, StyledButtonTypes } from "../../../../components";
import { normalize } from "../../../../hooks/useDataFetch";
import { getSavedValuesWithChecked } from "../../utils";

interface GroupedDropdownContentProps extends CustomOptionsComponentCommonProps {
    hasHeaderSearch?: boolean;
    firstGroupName?: string;
    secondGroupName: string;
    existingEntities?: any;
    selectedItems: { allIds: Array<any>; byId: { [key: string]: any } };
    CustomItemComponent?: FC;
}

export const GroupedDropdownContentSpecial = ({
    hasHeaderSearch,
    initialised,
    options,
    firstGroupName,
    secondGroupName,
    updateEntites,
    onChange
}: // selectedItems
GroupedDropdownContentProps) => {
    const {
        true: existingEntities,
        false: unselectedOptions,
        loading: loadingEntities
    } = useMemo(() => {
        return groupBy(
            options.allIds,
            (itemId) => (itemId === "loading" && "loading") || !!options.byId[itemId]?.checkedUpdated
        );
    }, [options]);

    const onCheckboxClick = (entity, checked) => {
        updateEntites({ [entity.id]: { ...entity, checkedUpdated: checked } });
        onChange?.(entity, checked);
    };

    return (
        <>
            {firstGroupName && (
                <>
                    {!!initialised && (
                        <div
                            className={classNames(
                                styles["content-sticky-title"],
                                styles["content-sticky-title_first"],
                                hasHeaderSearch && styles["content-sticky-title_first-with-header-search"]
                            )}
                        >
                            {firstGroupName}
                        </div>
                    )}
                    {existingEntities?.map((entityId: string) => {
                        const entity = options.byId[entityId];
                        return (
                            <MPDSelectItem
                                key={entityId}
                                disabled={entity?.disabled}
                                leftIcon={entity?.leftIcon}
                                option={entity}
                                withCheckbox
                                label={entity?.name || entity?.label}
                                onCheckBoxClick={onCheckboxClick}
                                checked={entity?.checkedUpdated}
                                children={entity?.children}
                                className={entityId === "loading" || !initialised ? Classes.SKELETON : ""}
                                tempState={entity?.checked !== entity.checkedUpdated}
                            />
                        );
                    })}
                </>
            )}
            {!!unselectedOptions?.length && !!initialised && (
                <div
                    className={classNames(
                        styles["content-sticky-title"],
                        hasHeaderSearch && styles["content-sticky-title_second-with-header-search"],
                        existingEntities?.length
                            ? styles["content-sticky-title_second"]
                            : styles["content-sticky-title_first"]
                    )}
                >
                    {secondGroupName}
                </div>
            )}
            {!!unselectedOptions?.length &&
                unselectedOptions?.map((entityId: string, index) => {
                    const entity = options.byId[entityId];
                    return (
                        <MPDSelectItem
                            disabled={entity?.disabled}
                            leftIcon={entity?.leftIcon}
                            key={entityId === "loading" || !initialised ? `${index}-loading` : entityId}
                            checked={entity?.checkedUpdated}
                            label={entity?.name || entity?.label}
                            option={entity}
                            withCheckbox
                            className={entityId === "loading" || !initialised ? Classes.SKELETON : ""}
                            onCheckBoxClick={onCheckboxClick}
                            children={entity?.children}
                            tempState={entity?.checked !== entity.checkedUpdated}
                        />
                    );
                })}
            {loadingEntities?.length &&
                loadingEntities.map((id, index) => {
                    return <MPDSelectItem loading key={`${id}-${index}`} className={Classes.SKELETON} />;
                })}
        </>
    );
};

type ContextType = {
    initialised: boolean;
    searchValue: string;
    onCheckboxClick: (entity: Option, checked: boolean) => void;
};

const GroupedDropdownContentContext = createContext<ContextType>({} as ContextType);

const Item = ({ entity, CustomItemComponent }) => {
    const { initialised, onCheckboxClick, searchValue }: ContextType =
        useContext<ContextType>(GroupedDropdownContentContext);
    const entityId = entity?.id;

    const commonProps = {
        LeftIconComponent: entity?.LeftIconComponent,
        disabled: entity?.disabled,
        leftIcon: entity?.leftIcon,
        checked: entity?.checkedUpdated,
        label: entity?.name || entity?.label,
        option: entity,
        withCheckbox: true,
        children: entity?.children,
        onCheckBoxClick: onCheckboxClick,
        searchValue: searchValue,
        className: entityId === "loading" || !initialised ? Classes.SKELETON : "",
        tempState: entity?.checked !== entity.checkedUpdated
    };

    if (CustomItemComponent) {
        return <CustomItemComponent {...commonProps} />;
    }
    return <MPDSelectItem {...commonProps} />;
};

export const GroupedDropdownContent = memo(
    ({
        hasHeaderSearch,
        initialised,
        options,
        firstGroupName,
        secondGroupName,
        onCheckBoxClick,
        updateEntites,
        // selectedItems,
        // unselectedItems,
        onChange,
        searchValue,
        CustomItemComponent,
        onApply,
        clear,
        values: notTransformedValues,
        initialValue: initialValueNotTransformed,
        closeDropdown,
        fieldNameForId = "id",
        initialValueWasUpdated
    }: GroupedDropdownContentProps) => {
        const values = useMemo(() => {
            return getSavedValuesWithChecked(notTransformedValues);
        }, [notTransformedValues]);

        const onUpdateEntites = (valueToNormalize, checkedReset?: boolean) => {
            const normalizedValues = normalize(valueToNormalize, { allIds: [], byId: {} }, fieldNameForId);
            updateEntites({
                ...normalizedValues.byId,
                ...options.allIds.reduce((acc, entityId) => {
                    const entity = options.byId[entityId];
                    let checked;
                    if (checkedReset) {
                        checked = !!normalizedValues.byId[entityId]?.checked;
                    } else {
                        checked = !!options.byId[entityId]?.checked;
                    }

                    acc[entityId] = {
                        ...entity,
                        checked,
                        checkedUpdated: normalizedValues.byId[entityId]
                            ? normalizedValues.byId[entityId].checkedUpdated
                            : false
                    };
                    return acc;
                }, {})
            });
        };

        useEffect(() => {
            onUpdateEntites(values, initialValueWasUpdated);
        }, [values, initialValueWasUpdated]);

        const {
            true: existingEntities,
            false: unselectedOptions,
            loading: loadingEntities
        } = useMemo(() => {
            return groupBy(
                options.allIds,
                (itemId) => (itemId === "loading" && "loading") || !!options.byId[itemId]?.checkedUpdated
            );
        }, [options]);

        const onCheckboxClick = (entity, checked) => {
            if (entity.isSelectAllOption) {
                onChange([entity], checked, { ...entity, checkedUpdated: true });
                return;
            }

            if (checked) {
                onChange?.([
                    ...values.filter((entity) => !entity.isSelectAllOption),
                    { ...entity, checked: !!entity.checked, checkedUpdated: checked }
                ]);
            } else {
                onChange?.(values.filter((alreadySelectedEntity) => entity.id !== alreadySelectedEntity.id));
            }
        };

        const onClearClick = () => {
            onChange?.([]);
        };

        const onApplyClick = async () => {
            await onApply?.(values);
            closeDropdown();
        };

        const contextValue = useMemo(() => {
            return {
                initialised,
                searchValue,
                onCheckboxClick
            };
        }, [initialised, onCheckboxClick, searchValue]);

        return (
            <GroupedDropdownContentContext.Provider value={contextValue}>
                <div id={"id-for-get-width"}>
                    {firstGroupName && !!existingEntities?.length && (
                        <>
                            {!!initialised && (
                                <div
                                    className={classNames(
                                        styles["content-sticky-title"],
                                        styles["content-sticky-title_first"],
                                        hasHeaderSearch && styles["content-sticky-title_first-with-header-search"]
                                    )}
                                >
                                    {firstGroupName}
                                </div>
                            )}
                            {existingEntities?.map((entityId: string, index: number) => {
                                const entity = options.byId[entityId];
                                return (
                                    <Item
                                        key={entityId === "loading" || !initialised ? `${index}-loading` : entityId}
                                        entity={entity}
                                        CustomItemComponent={CustomItemComponent}
                                    />
                                );
                            })}
                        </>
                    )}
                    {!!unselectedOptions?.length && !!initialised && (
                        <div
                            className={classNames(
                                styles["content-sticky-title"],
                                hasHeaderSearch && styles["content-sticky-title_second-with-header-search"],
                                existingEntities?.length
                                    ? styles["content-sticky-title_second"]
                                    : styles["content-sticky-title_first"]
                            )}
                        >
                            {secondGroupName}
                        </div>
                    )}
                    {!!unselectedOptions?.length &&
                        unselectedOptions?.map((entityId: string, index) => {
                            const entity = options.byId[entityId];
                            return (
                                <Item
                                    key={entityId === "loading" || !initialised ? `${index}-loading` : entityId}
                                    entity={entity}
                                    CustomItemComponent={CustomItemComponent}
                                />
                            );
                        })}
                    {loadingEntities?.length &&
                        loadingEntities.map((id, index) => {
                            return <MPDSelectItem loading key={`${id}-${index}`} className={Classes.SKELETON} />;
                        })}
                    {(onApply || clear) && (
                        <div className={styles["content-footer"]}>
                            {clear && (
                                <StyledButton
                                    text={"Clear"}
                                    type={StyledButtonTypes.primarySimple}
                                    onClick={onClearClick}
                                />
                            )}
                            {onApply && (
                                <StyledButton text={"Apply"} type={StyledButtonTypes.primary} onClick={onApplyClick} />
                            )}
                        </div>
                    )}
                </div>
            </GroupedDropdownContentContext.Provider>
        );
    }
);
