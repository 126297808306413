import { memo, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Position, Text } from "@blueprintjs/core";
import moment from "moment";
import classNames from "classnames";
import { areEqual } from "react-window";

import { StyledCheckbox, Tag } from "../../../../../components";
import { ContactActivityChart } from "../../../../../components/contact-activity-chart/contact-activity-chart";
import { MPDIcon, TSelectItemTypes } from "../../../../../mpd-library";
import styles from "./contacts-list-item.module.scss";
import { Tooltip, TooltipClsasses } from "../../../../../components/tooltip/tooltip";
import { Dropdown } from "../../../../../mpd-library/dropdown/dropdown";
import { DropdownTargetClasses } from "src/mpd-library/dropdown/components/target/constants";
import { ReactComponent as VerticalTripleLineIcon } from "src/mpd-library/icon/assets/vertical-triple-line.svg";

import { ReactComponent as ChannelsPhoneIcon } from "src/mpd-library/icon/assets/ChannelsPhone.svg";
import { ReactComponent as ChannelsSmsIcon } from "src/mpd-library/icon/assets/ChannelsSms.svg";
import { ReactComponent as ChannelsMailIcon } from "src/mpd-library/icon/assets/ChannelsMail.svg";
import { ReactComponent as ChannelsFacebookIcon } from "src/mpd-library/icon/assets/ChannelsFacebook.svg";
import { ReactComponent as ChannelsInstagramIcon } from "src/mpd-library/icon/assets/ChannelsInstagram.svg";
import { ReactComponent as ChannelsTwitterIcon } from "src/mpd-library/icon/assets/ChannelsTwitter.svg";
import { ReactComponent as ChannelsAtlasOneIcon } from "src/mpd-library/icon/assets/ChannelsAtlasOne.svg";
import { ContactsContext } from "../../context";
import { useHistory } from "react-router";

export const CHANNEL_TYPE_MATCH_ICON = {
    facebook: ChannelsFacebookIcon,
    instagram: ChannelsInstagramIcon,
    twitter: ChannelsTwitterIcon,
    atlas_one: ChannelsAtlasOneIcon,
    email: ChannelsMailIcon,
    landline: ChannelsPhoneIcon,
    mobile: ChannelsSmsIcon
};

const classname = "contacts-list-item";

//   24px minmax(0, 2fr) minmax(284px, 2fr) minmax(110px, 2fr) minmax(100px, 2fr) minmax(50px, 200px)
//         32px;

moment.defineLocale("en-cust3", {
    relativeTime: {
        future: "in %s",
        past: "%s",
        s: "Just now",
        ss: "%ds",
        m: "1m",
        mm: "%dm",
        h: "1h",
        hh: "%dh",
        d: "1d",
        dd: "%dd",
        M: "1m",
        MM: "%dm",
        y: "1y",
        yy: "%dy"
    }
});

const matchTemplate = {
    checkbox: "24px",
    name: "minmax(170px, 2fr)",
    channels: "minmax(284px, 2fr)",
    activity: "minmax(110px, 2fr)",
    last_contacted_at: "minmax(130px, 2fr)",
    tags: "minmax(100px, 2fr)",
    lists: "minmax(100px, 2fr)",
    external_id: "minmax(110px, 2fr)",
    more: "32px"
};

// "24px minmax(0, 2fr) minmax(284px, 2fr) minmax(110px, 2fr) minmax(100px, 2fr) 32px"

const getGridTemplateColumns = (config) => {
    const res = config.allIds.reduce((acc, fieldName) => {
        console.l;
        if (config?.byId?.[fieldName]?.enabled) {
            acc = `${acc} ${matchTemplate[fieldName]}`.trim();
        }
        return acc;
    }, "");
    return { gridTemplateColumns: res };
};

export const ContactsListHeader = ({ onSelectAll, indeterminate, checked, listItemConfig, disableCheckbox }) => {
    const template = useMemo(() => getGridTemplateColumns(listItemConfig), [listItemConfig]);
    return (
        <div className={styles[`contacts-list-header`]} style={template}>
            {listItemConfig?.allIds?.map((fieldName) => {
                const enabled = listItemConfig?.byId?.[fieldName]?.enabled;
                if (enabled) {
                    if (fieldName === "channels") {
                        return <span className={styles["contacts-list-header-label"]}>CHANNELS</span>;
                    }
                    if (fieldName === "checkbox") {
                        return (
                            <span>
                                <StyledCheckbox
                                    className={styles[`checkbox`]}
                                    indeterminate={indeterminate}
                                    checked={checked}
                                    onChange={(e) => onSelectAll(indeterminate ? false : e.target.checked)}
                                    disabled={disableCheckbox}
                                />
                            </span>
                        );
                    }
                    if (fieldName === "name") {
                        return <span className={styles["contacts-list-header-label"]}>NAME</span>;
                    }
                    if (fieldName === "tags") {
                        return <span className={styles["contacts-list-header-label"]}>TAGS</span>;
                    }
                    if (fieldName === "activity") {
                        return <span className={styles["contacts-list-header-label"]}>ACTIVITY</span>;
                    }
                    if (fieldName === "last_contacted_at") {
                        return <span className={styles["contacts-list-header-label"]}>LAST CONTACTED</span>;
                    }
                    if (fieldName === "more") {
                        return <span />;
                    }
                    if (fieldName === "lists") {
                        return <span className={styles["contacts-list-header-label"]}>LISTS</span>;
                    }
                    if (fieldName === "external_id") {
                        return <span className={styles["contacts-list-header-label"]}>CONTACT ID</span>;
                    }
                } else return null;
            })}
        </div>
    );
};

export const ContactsListItem = memo(
    ({
        index,
        data,
        style,
        loading,
        checked,
        onUserSelect,
        onSelectContact,
        listItemConfig,
        checkboxDisabled,
        hideBottomBorder
    }) => {
        const { setDeletableContacts } = useContext(ContactsContext);
        const item = data;

        const loadingState = loading || !item;

        const template = useMemo(() => getGridTemplateColumns(listItemConfig), [listItemConfig]);

        const mainWrapperStyles = useMemo(() => ({ ...style, ...template }), [style, template]);

        const history = useHistory();

        return (
            <td
                className={classNames(styles[classname], hideBottomBorder && styles[`${classname}_hide-border`])}
                style={mainWrapperStyles}
                onClick={() => onUserSelect(item.id)}
            >
                {listItemConfig?.allIds?.map((fieldName: string) => {
                    const enabled = listItemConfig?.byId?.[fieldName]?.enabled;
                    console.log("SWITCH CASE >>>>", enabled, fieldName);
                    if (enabled) {
                        if (fieldName === "channels") {
                            return (
                                <div
                                    className={classNames(
                                        styles[`${classname}-channels-wrapper`],
                                        loadingState && "bp4-skeleton"
                                    )}
                                >
                                    {item?.channels?.map((channel) => {
                                        return (
                                            <Tooltip
                                                // isOpen={index === 0 && itemIndex === 0}
                                                hoverOpenDelay={1000}
                                                portalClassName={TooltipClsasses.primary}
                                                position={Position.BOTTOM}
                                                content={channel.identifier}
                                                tootltipTarget={
                                                    <MPDIcon
                                                        key={`${CHANNEL_TYPE_MATCH_ICON[channel.type]}-icon`}
                                                        IconComponent={
                                                            CHANNEL_TYPE_MATCH_ICON[channel.type] ||
                                                            CHANNEL_TYPE_MATCH_ICON[channel.phone_type]
                                                        }
                                                        size={32}
                                                    />
                                                }
                                            ></Tooltip>
                                        );
                                    })}
                                </div>
                            );
                        }
                        if (fieldName === "checkbox") {
                            return (
                                <>
                                    {checkboxDisabled ? (
                                        <Tooltip
                                            // isOpen={index === 0 && itemIndex === 0}
                                            hoverOpenDelay={1000}
                                            portalClassName={TooltipClsasses.primary}
                                            position={Position.BOTTOM}
                                            content={"Remove select all checkbox"}
                                            tootltipTarget={
                                                <StyledCheckbox
                                                    checked={checked}
                                                    className={styles[`checkbox`]}
                                                    onChange={(e) => onSelectContact(e, item)}
                                                    disabled
                                                />
                                            }
                                        />
                                    ) : (
                                        <StyledCheckbox
                                            checked={checked}
                                            className={styles[`checkbox`]}
                                            onChange={(e) => onSelectContact(e, item)}
                                            disabled={loadingState}
                                        />
                                    )}
                                </>
                            );
                        }
                        if (fieldName === "more") {
                            return (
                                <>
                                    {!loadingState && (
                                        <div className={styles[`${classname}-more-dropdown-wrapper`]}>
                                            <Dropdown
                                                disabled={loadingState}
                                                id={"MOREDROPDOWN"}
                                                staticOptions={[
                                                    {
                                                        label: "View Record",
                                                        value: null,
                                                        onClick: () => history.push(`/contacts/${item.id}`)
                                                    },
                                                    {
                                                        label: "Delete permanently",
                                                        value: null,
                                                        type: TSelectItemTypes.delete,
                                                        onClick: () => setDeletableContacts([item.id])
                                                    }
                                                ]}
                                                usePortal
                                                targetClassName={classNames(
                                                    DropdownTargetClasses.SELECT,
                                                    DropdownTargetClasses.MORE
                                                )}
                                                disableArrowAnimation
                                                ArrowIconComponent={VerticalTripleLineIcon}
                                                position={Position.BOTTOM_LEFT}
                                                portalStopPropagationEvents={["click"]}
                                                contentBlockWidth={220}
                                            />
                                        </div>
                                    )}
                                </>
                            );
                        }
                        if (fieldName === "external_id") {
                            return (
                                <div
                                    className={classNames(styles[`${classname}-name`], loadingState && "bp4-skeleton")}
                                >
                                    {item?.external_id || "~"}
                                </div>
                            );
                        }
                        if (fieldName === "name") {
                            return (
                                <div
                                    className={classNames(styles[`${classname}-name`], loadingState && "bp4-skeleton")}
                                >{`${item?.first_name || ""} ${item?.last_name || ""}`}</div>
                            );
                        }
                        if (fieldName === "tags") {
                            return (
                                <div className={classNames(styles["tags-wrapper"], loadingState && "bp4-skeleton")}>
                                    {!!item?.tags?.length && (
                                        <>
                                            <Tag label={item?.tags[0]?.name} withTooltip />
                                            {item?.tags?.length > 1 && (
                                                <Tag
                                                    withTooltip
                                                    isCounter
                                                    label={`+${item?.tags.length - 1}`}
                                                    tooltipContent={
                                                        <div className={styles["tags-tooltip-list"]}>
                                                            {item?.tags?.slice(1).map((tag) => {
                                                                return <div>{tag.name}</div>;
                                                            })}
                                                        </div>
                                                    }
                                                />
                                            )}
                                        </>
                                    )}
                                </div>
                            );
                        }
                        if (fieldName === "lists") {
                            return (
                                <div className={classNames(styles["tags-wrapper"], loadingState && "bp4-skeleton")}>
                                    {!!item?.lists?.length && (
                                        <>
                                            <Tag label={item?.lists[0]?.name} withTooltip />
                                            {item?.lists?.length > 1 && (
                                                <Tag
                                                    withTooltip
                                                    isCounter
                                                    label={`+${item?.lists.length - 1}`}
                                                    tooltipContent={
                                                        <div className={styles["tags-tooltip-list"]}>
                                                            {item?.lists?.slice(1).map((list) => {
                                                                return <div>{list.name}</div>;
                                                            })}
                                                        </div>
                                                    }
                                                />
                                            )}
                                        </>
                                    )}
                                </div>
                            );
                        }
                        if (fieldName === "activity") {
                            return (
                                <div
                                    className={classNames(
                                        styles[`${classname}-chart-wrapper`],
                                        loadingState && "bp4-skeleton"
                                    )}
                                >
                                    <ContactActivityChart data={item?.engagement_stats} />
                                </div>
                            );
                        }
                        if (fieldName === "last_contacted_at") {
                            console.log("last_contacted_at");
                            return (
                                <div
                                    className={classNames(
                                        styles[`${classname}-last-contacted`],
                                        loadingState && "bp4-skeleton"
                                    )}
                                >
                                    {item?.last_contacted_at ? moment(item?.last_contacted_at).fromNow() : "Never"}
                                </div>
                            );
                        }
                    } else return null;
                })}
            </td>
        );
    },
    areEqual
);
