import { FC, useContext, useMemo, useState } from "react";
import pluralize from "pluralize";
import { StyledAlert, StyledAlertTypes, StyledButton, StyledButtonTypes, Tag } from "../../../../../components";
import styles from "./AddTagsDialog.module.scss";
import { CustomOptionsComponentProps, Dropdown } from "../../../../../mpd-library/dropdown/dropdown";
import { DropdownTargetClasses } from "src/mpd-library/dropdown/components/target/constants";
import classNames from "classnames";
import { SelectedContacts } from "../selected-contacts/SelectedContacts";
import { assignTags, createContactsTag } from "../../../../../requests/contactsRequests";
import { getTags } from "src/requests/tagsRequests";
import { Position } from "@blueprintjs/core";
import { GroupedDropdownContent } from "../../../../../mpd-library/dropdown/components/grouped-dropdown-content/grouped-dropdown-content";
import { useFormik } from "formik";
import { array, object, string } from "yup";
import { ContactsListContext } from "../../contacts-list";
import { Classes, TSelectItemTypes } from "../../../../../mpd-library";
import { SummaryContext } from "../../../contacts";
import { ReactComponent as TagIcon } from "src/mpd-library/icon/assets/tag.svg";
import { createTagRequest } from "../../../../../requests/tagsRequests";
import { TagDropdownListItem } from "../../../../../mpd-library/dropdown/components/tag-dropdown-list-item/TagDropdownListItem";
import { useDispatch } from "react-redux";
import { onUpdateToast } from "../../../../../actions/global/global";
import { Link } from "react-router-dom";

const FETCH_TAGS_DATA_PARAMS = {
    feature: "contacts"
};

interface AddTagsDialogProps {}

export const AddTagsDialog: FC<AddTagsDialogProps> = ({
    selectedContacts,
    filters,
    setContactsListForceReload
}: AddTagsDialogProps): JSX.Element => {
    const { updateContacts } = useContext(ContactsListContext);
    const { setSummary, summary } = useContext(SummaryContext);
    const [assignTagsProcessing, setAssignTagsProcessing] = useState<boolean>(false);
    const [removeTagsPopoverOpen, setRemoveTagPopoverOpen] = useState<boolean>(false);
    const [addTagsPopoverOpen, setAddTagsPopoverOpen] = useState<boolean>(false);
    const dispatch = useDispatch();

    const formik = useFormik({
        // ...formikConfig,
        initialValues: {
            tags: []
        },
        isInitialValid: false,
        validationSchema: object().shape({
            tags: array().min(1, "Select at least one tag")
        }),
        onSubmit: async (values) => {
            try {
                setAssignTagsProcessing(true);
                const tag_ids = values.tags.map((tag) => tag.id);
                const contact_ids = selectedContacts !== "all" ? Object.keys(selectedContacts) : undefined;
                const payload = {
                    contact_ids,
                    tag_ids,
                    filters
                };

                await assignTags(payload);
                if (selectedContacts !== "all") {
                    updateContacts(
                        Object.keys(selectedContacts).reduce((acc, contactId: string) => {
                            return {
                                ...acc,
                                [contactId]: {
                                    ...selectedContacts[contactId],
                                    tags: [
                                        ...(selectedContacts[contactId]?.tags || []),
                                        ...values.tags
                                        // ...values.tags.allIds.map((tagId) => values.tags.byId[tagId])
                                    ]
                                }
                            };
                        }, {})
                    );
                } else {
                    setContactsListForceReload(true);
                }

                dispatch(
                    onUpdateToast({
                        value: `${tag_ids?.length > 1 ? "Tags were" : "Tag was"} successfully assigned to ${
                            contact_ids?.length > 1 || selectedContacts === "all" ? "contacts" : "contact"
                        }`,
                        type: "saved"
                    })
                );
            } catch (err) {
                console.log("ERR >>>>>", err);
            } finally {
                setAssignTagsProcessing(false);
            }
        }
    });

    const createNewTagProps = useMemo(
        () => ({
            create: async (data) => await createTagRequest({ ...data, enable_for_contacts: true }),
            entityName: "tag",
            onCreateNewEntityClick: (value) => {
                const updatedSummary = { ...summary, tags: [...summary?.tags, value] };
                setSummary(updatedSummary);
            }
        }),
        [createTagRequest]
    );

    return (
        <>
            <Dropdown
                LeftIconComponent={TagIcon}
                selectedValue={{ label: "Tags", value: "initial" }}
                targetClassName={DropdownTargetClasses.SELECT}
                staticOptions={[
                    { label: "Add Tags", onClick: () => setAddTagsPopoverOpen(true) },
                    {
                        label: "Remove Tags",
                        onClick: () => setRemoveTagPopoverOpen(true),
                        type: TSelectItemTypes.delete
                    }
                ]}
                onIntercationCustom={(state) => {
                    if (state) {
                        setAddTagsPopoverOpen(false);
                        setRemoveTagPopoverOpen(false);
                    }
                }}
                position={Position.BOTTOM_RIGHT}
                contentBlockWidth={220}
            />
            <StyledAlert
                className={styles["save-view-dialog"]}
                isOpen={addTagsPopoverOpen || removeTagsPopoverOpen}
                type={StyledAlertTypes.primary}
                canOutsideClickCancel={true}
                canEscapeKeyCancel={true}
                cancelButtonText={"Cancel"}
                confirmButtonText={addTagsPopoverOpen ? "Add" : "Remove"}
                confirmButtonProps={{
                    type: addTagsPopoverOpen ? StyledButtonTypes.primary : StyledButtonTypes.delete,
                    disabled: !formik.isValid,
                    processing: assignTagsProcessing
                }}
                onConfirm={formik.submitForm}
                globalContentProps={{
                    onCancel: () => null,
                    title: addTagsPopoverOpen ? "Add Tags" : "Remove Tags",
                    hideDescription: true
                }}
                leftFooterComponent={
                    <Link to="/account-settings/tags">
                        <StyledButton type={StyledButtonTypes.primarySimple} text={"Manage Tags"} />
                    </Link>
                }
            >
                <div className={classNames(styles["main-content-wrapper"], Classes.CONTAINER_INPUTS_ROW_GAP)}>
                    <SelectedContacts selectedContacts={selectedContacts} />

                    <Dropdown
                        initialValue={formik.initialValues.tags}
                        title={"TAGS"}
                        searchInputPlaceholder={"Search for tags..."}
                        withSearch
                        targetClassName={DropdownTargetClasses.FORM_SELECT}
                        fetchDataRequest={getTags}
                        fetchDataParams={FETCH_TAGS_DATA_PARAMS}
                        entityKey="tags"
                        position={Position.BOTTOM}
                        contentBlockHeight={233}
                        CustomOptionsComponent={({ ...props }: CustomOptionsComponentProps) => (
                            <GroupedDropdownContent
                                {...props}
                                CustomItemComponent={(props) => (
                                    <TagDropdownListItem
                                        {...props}
                                        type={
                                            addTagsPopoverOpen
                                                ? TSelectItemTypes.primary
                                                : TSelectItemTypes.itemToRemove
                                        }
                                    />
                                )}
                                firstGroupName={"SELCTED TAGS"}
                                secondGroupName={addTagsPopoverOpen ? "Add tags" : "Remove tags"}
                            />
                        )}
                        SelectedItemComponent={({ item, remove }) => <Tag label={item?.name} onDeleteClick={remove} />}
                        {...formik.getFieldProps("tags")}
                        values={formik.values.tags || []}
                        createNewEntityProps={createNewTagProps}
                        ArrowIconComponent
                        onChange={(value) => {
                            formik.setFieldValue("tags", value);
                        }}
                    />
                </div>
            </StyledAlert>
        </>
    );

    return (
        <>
            <StyledAlert
                Target={({ onClick }) => (
                    <StyledButton
                        text={"Tags"}
                        type={StyledButtonTypes.secondary}
                        LeftIconComponent={TagIcon}
                        onClick={onClick}
                    />
                )}
                className={styles["save-view-dialog"]}
                // isOpen={isOpen}
                type={StyledAlertTypes.primary}
                canOutsideClickCancel={true}
                canEscapeKeyCancel={true}
                cancelButtonText={"Cancel"}
                confirmButtonText={"Add"}
                confirmButtonProps={{
                    type: StyledButtonTypes.primary,
                    disabled: !formik.isValid,
                    processing: assignTagsProcessing
                }}
                onConfirm={formik.submitForm}
                globalContentProps={{
                    onCancel: () => null,
                    title: "Add Tags",
                    hideDescription: true
                }}
                leftFooterComponent={
                    <Link to="/account-settings/tags">
                        <StyledButton type={StyledButtonTypes.primarySimple} text={"Manage Tags"} />
                    </Link>
                }
            >
                <div className={classNames(styles["main-content-wrapper"], Classes.CONTAINER_INPUTS_ROW_GAP)}>
                    <SelectedContacts selectedContacts={selectedContacts} />

                    <Dropdown
                        initialValue={formik.initialValues.tags}
                        title={"TAGS"}
                        searchInputPlaceholder={"Search for tags..."}
                        withSearch
                        targetClassName={DropdownTargetClasses.FORM_SELECT}
                        fetchDataRequest={getTags}
                        fetchDataParams={FETCH_TAGS_DATA_PARAMS}
                        entityKey="tags"
                        position={Position.BOTTOM}
                        contentBlockHeight={233}
                        CustomOptionsComponent={({ ...props }: CustomOptionsComponentProps) => (
                            <GroupedDropdownContent
                                {...props}
                                CustomItemComponent={(props) => <TagDropdownListItem {...props} />}
                                firstGroupName={"SELCTED TAGS"}
                                secondGroupName={"Add tags"}
                            />
                        )}
                        SelectedItemComponent={({ item, remove }) => <Tag label={item?.name} onDeleteClick={remove} />}
                        {...formik.getFieldProps("tags")}
                        values={formik.values.tags || []}
                        createNewEntityProps={createNewTagProps}
                        ArrowIconComponent
                        onChange={(value) => {
                            formik.setFieldValue("tags", value);
                        }}
                    />
                </div>
            </StyledAlert>
        </>
    );
};
