import { MPDIcon } from "../../mpd-library";
import styles from "./styles.module.scss";
import classNames from "classnames";
import { ReactComponent as RemoveCircleIcon } from "../../mpd-library/icon/assets/remove-circle.svg";
import { Tooltip, TooltipClsasses } from "../tooltip/tooltip";
import { createContext, forwardRef, useContext } from "react";
import { Position } from "@blueprintjs/core";

interface TagProps {
    id: string;
    tooltipContent?: JSX.Element;
    label: string | JSX.Element;
    withTooltip?: boolean;
    isCounter?: boolean;
    className?: string;
    color?: string;
    onDeleteClick?: (id: string) => void;
}

export const TagTypes = {
    list: "list",
    tag: "tag"
};

const TagClasses = {
    tag: styles["tag-main-wrapper"],
    list: styles["list-main-wrapper"]
};

const TagContext = createContext<TagProps>({} as TagProps);

const TagTooltipTarget = forwardRef((_, ref) => {
    const tagContext = useContext<TagProps>(TagContext);

    const onDelete = (e) => {
        e.stopPropagation();
        tagContext.onDeleteClick?.(tagContext.id);
    };
    return (
        <div
            className={classNames(
                tagContext.type ? TagClasses[tagContext.type] : styles["tag-main-wrapper"],
                tagContext.withTooltip && styles["tag-main-wrapper_with-tooltip"],
                tagContext.isCounter && styles["counter"],
                tagContext.className
            )}
            // style={{ backgroundColor: color }}
        >
            <div className={styles["tag-label"]} ref={ref}>
                {tagContext.label}
            </div>

            {!!tagContext.onDeleteClick && (
                <button className={styles["remove-button"]} onClick={onDelete}>
                    <MPDIcon IconComponent={RemoveCircleIcon} />
                </button>
            )}
        </div>
    );
});

export const Tag = (props: TagProps) => {
    return (
        <TagContext.Provider value={props}>
            <Tooltip
                TooltipTargetComponent={TagTooltipTarget}
                content={props.tooltipContent || props.label}
                contentClassName={TooltipClsasses.primary}
                checkIfEllipsis={!props.isCounter}
                position={Position.BOTTOM}
            />
        </TagContext.Provider>
    );
};
