import { Position } from "@blueprintjs/core";
import { StyledCheckbox, StyledFloatingInput, StyledFloatingInputTypes, Tag } from "../../../../../../../components";
import {
    CustomOptionsComponentProps,
    Dropdown,
    ICreateNewEntityProps
} from "../../../../../../../mpd-library/dropdown/dropdown";
import { DropdownTargetClasses } from "src/mpd-library/dropdown/components/target/constants";
import styles from "./Details.module.scss";
import { Classes } from "../../../../../../../mpd-library";
import classNames from "classnames";
import { useMemo } from "react";
import { TCreateNewContactTagParams, createContactsTag } from "../../../../../../../requests/contactsRequests";
import { getTags } from "src/requests/tagsRequests";
import { GroupedDropdownContent } from "../../../../../../../mpd-library/dropdown/components/grouped-dropdown-content/grouped-dropdown-content";
import { createTagRequest } from "../../../../../../../requests/tagsRequests";
import { TagDropdownListItem } from "../../../../../../../mpd-library/dropdown/components/tag-dropdown-list-item/TagDropdownListItem";

const FETCH_TAGS_DATA_PARAMS = {
    feature: "contacts"
};

export const Details = ({ formik }) => {
    const createNewTagProps = useMemo<ICreateNewEntityProps<TCreateNewContactTagParams>>(
        () => ({
            create: (data) => createTagRequest({ ...data, enable_for_contacts: true }),
            entityName: "tag"
        }),
        [createContactsTag]
    );

    return (
        <div className={classNames(styles["root"], Classes.CONTAINER_INPUTS_ROW_GAP)}>
            <StyledFloatingInput
                placeholder={"IMPORT NAME"}
                floatingType={StyledFloatingInputTypes.primary}
                error={formik.errors.name}
                {...formik.getFieldProps("name")}
            />
            <Dropdown
                usePortal={false}
                title={"ADD ADDITIONAL TAGS"}
                searchInputPlaceholder={"Search for tags..."}
                withSearch
                leftIcon={"search"}
                targetClassName={DropdownTargetClasses.FORM_SELECT}
                fetchDataRequest={getTags}
                fetchDataParams={FETCH_TAGS_DATA_PARAMS}
                entityKey="tags"
                position={Position.BOTTOM}
                contentBlockHeight={233}
                CustomOptionsComponent={({ ...props }: CustomOptionsComponentProps) => {
                    return (
                        <GroupedDropdownContent
                            {...props}
                            CustomItemComponent={(props) => <TagDropdownListItem {...props} />}
                            secondGroupName={"Add tags"}
                            firstGroupName="Selected tags"
                        />
                    );
                }}
                footerProps={{}}
                SelectedItemComponent={({ item, remove }) => <Tag label={item?.name} onDeleteClick={remove} />}
                {...formik.getFieldProps("tags")}
                initialValue={formik.initialValues.tags}
                values={formik.values.tags || []}
                onChange={(value) => {
                    formik.setFieldValue("tags", value);
                }}
                createNewEntityProps={createNewTagProps}
                ArrowIconComponent
            />
            <StyledCheckbox
                label={"Create a list from this import"}
                className={styles["create-list-checkbox"]}
                {...formik.getFieldProps("create_list")}
            />
        </div>
    );
};
