import { useRef } from "react";
import { mixed, object, string, boolean, array } from "yup";
import { FormikConfig } from "formik";
import { StyledButton, StyledButtonTypes, StyledCheckbox } from "../../../../../components";
import { DialogStepper } from "../../../../../components/dialog-stpper/DialogStepper";
import { UploadFile } from "./components/upload-file/UploadFile";
import { MapColumns } from "./components/map-columns/MapColumns";
import { Details } from "./components/details/Details";
import { importContactFinal, importContacts } from "../../../../../requests/contactsRequests";
import styles from "./ImportContactsStepper.module.scss";
import { ImportToUnmapped } from "./components/import-to-unmapped/ImportToUnmapped";
import { calculateUnmapped } from "./utils";
import { ReactComponent as DrawerDownloadIcon } from "src/mpd-library/icon/assets/drawer-download.svg";

const VALIDATION_SCHEMA = [
    object().shape({
        file: mixed().required("File is required")
    }),
    object().shape({
        canGoNext: boolean().oneOf([true])
    }),
    object().shape({
        name: string().nullable().required("Import name is required"),
        tags: array(),
        create_list: boolean()
    })
];

const STEPS_FIELDS_MATCH_INDEX = {
    name: 2
};

const FORMIK_CONFIG: Partial<FormikConfig<any>> = {
    initialValues: {
        file: null,
        name: null,
        import_id: null,
        mapped_fields: [],
        create_list: false,
        tags: []
    },
    validationSchema: VALIDATION_SCHEMA
};

export const ImportContactsStepper = ({ onTargetClick, setFilterByImport }) => {
    const initialMappedFields = useRef();

    const submit = async (values) => {
        const { import_id, mapped_fields, name, tags, create_list } = values;
        const res = await importContactFinal({
            import_id,
            fields: mapped_fields,
            name,
            tag_ids: tags.map((tag) => tag.id),
            create_list
        });
        const valueForFilter = {
            conditions: [
                {
                    conditions: [
                        {
                            comparison: "eq",
                            field: "import_id",
                            isComparisonValueEditable: true,
                            label: "Import",
                            type: "import_id",
                            value: res.data.import.id,
                            meta: { name: res.data.import.name }
                        }
                    ],
                    operator: "and"
                }
            ],
            operator: "and"
        };

        setFilterByImport(valueForFilter);
    };

    const onNextClick = async (activeStepIndex: number, formik: any) => {
        if (activeStepIndex === 0) {
            const { file } = formik.values;
            const formData = new FormData();
            formData.append("file", file);
            const res = await importContacts(formData);
            const {
                data: { import_id, mapped_fields }
            } = res;

            const converted_mapped_fields = mapped_fields.map((field) => {
                return {
                    ...field,
                    on_conflict: "overwrite"
                };
            });

            initialMappedFields.current = converted_mapped_fields;

            const { unmappedCounter: unmappedFieldsCounter } = calculateUnmapped(converted_mapped_fields);

            await formik.setValues({
                ...formik.values,
                import_id,
                mapped_fields: converted_mapped_fields,
                canGoNext: formik.values.canGoNext || unmappedFieldsCounter === 0
            });
        }

        return;
    };

    return (
        <DialogStepper
            submit={submit}
            renderTarget={(onModalOpen) => (
                <StyledButton
                    className={styles["import-button"]}
                    text={"Import"}
                    LeftIconComponent={DrawerDownloadIcon}
                    type={StyledButtonTypes.secondary}
                    onClick={onModalOpen}
                />
            )}
            stepsPanels={[
                {
                    title: "Upload your file",
                    subtitle:
                        "To start your import, please upload a CSV or Excel file below that follows the Atlas One guidelines and includes a unique ID for each user.",
                    stepLabel: "UPLOAD",
                    component: UploadFile
                },
                {
                    title: "Map columns in your file to contact properties",
                    subtitle:
                        "Each column header below should be mapped to a contact property in Atlas One. Some of these have already been mapped based on their names. Anything that hasn't been mapped yet can be manually mapped to a contact property with the dropdown menu. ",

                    stepLabel: "map fields",
                    component: MapColumns
                },
                {
                    title: "A few final details",
                    stepLabel: "Details",
                    component: Details
                }
            ]}
            fieldStepMatch={STEPS_FIELDS_MATCH_INDEX}
            formikConfig={FORMIK_CONFIG}
            onNextClickCustom={onNextClick}
            renderFooterMiddleComponent={({ activeStepIndex, ...remain }) => {
                if (activeStepIndex === 1) {
                    return <ImportToUnmapped {...remain} />;
                }
            }}
        />
    );
};
