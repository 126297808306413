import { ComponentType } from "react";
import get from "lodash/get";
import { normalize } from "../../hooks/useDataFetch";

export const dropdownListItemTransformer = ({
    idPath = "id",
    leftIconPath,
    LeftIconComponent,
    labelPartsPaths,
    labelPath,
    options
}: {
    idPath?: string;
    leftIconPath?: string;
    LeftIconComponent?: ComponentType<{ option: Required<{ id: string }> }>;
    labelPartsPaths?: Array<string>;
    labelPath?: string;
    options: Array<Required<{ id: string }>>;
}) => {
    return options?.map((option) => ({
        ...option,
        id: option[idPath],
        LeftIconComponent: () => <LeftIconComponent option={option} />,
        label: labelPath
            ? option[labelPath]
            : labelPartsPaths.reduce((acc: string, path: string) => acc + " " + option[path], "").trim(),
        value: option
    }));
};

export const getSavedValuesWithChecked = (initialValues) => {
    return initialValues?.map((entity) => ({
        ...entity,
        checked: typeof entity.checked !== "undefined" ? entity.checked : true,
        checkedUpdated: true
    }));
};

export const getName = (entity, pattern) => {
    const fields = pattern.split(/[^a-zA-Z_.]+/);
    const name = fields.reduce((acc, path) => {
        return acc.replace(path, get(entity, path));
    }, pattern);

    return name || "name";
};

const fetchDataReqAddChecked = (foo, entityKey, initialValue) => async (params) => {
    const res = await foo(params);
    const initialValueNormalized = normalize(initialValue, { allIds: [], byId: {} }, entityKey);
    const updatedRes = {
        ...res,
        data: {
            ...res.data,
            [entityKey]: res.data[entityKey].map((entity) => {
                const name = getName(entity, "name created_by_admin.first_name created_by_admin.last_name");
                return {
                    ...entity,
                    name,
                    checked: !!initialValueNormalized.byId[entity.id],
                    checkedUpdated: !!initialValueNormalized.byId[entity.id]
                };
            })
        }
    };
    return updatedRes;
};
