import { useState } from "react";
import classNames from "classnames";
import pluralize from "pluralize";
import { StyledAlert, StyledAlertTypes, StyledButton, StyledButtonTypes } from "../../../../../components";
import styles from "./AddToStaticListDialog.module.scss";
import { SelectedContacts } from "../selected-contacts/SelectedContacts";
import { Classes } from "../../../../../mpd-library";
import { assignToList, getContactsSummary } from "../../../../../requests/contactsRequests";
import { StaticListsDropdown } from "../static-lists-dropdown/StaticListsDropdown";
import { array, object } from "yup";
import { useFormik } from "formik";
import { SummaryContext } from "../../../contacts";
import { convertSummary } from "../../../contacts-groups/contacts-groups";
import { ReactComponent as SelectedPersonsIcon } from "src/mpd-library/icon/assets/SelectedPersons.svg";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { onUpdateToast } from "../../../../../actions/global/global";

export const AddToStaticListDialog = ({ selectedContacts, filters }) => {
    const [addToListsProcessing, setAddToListsProcessing] = useState<boolean>(false);
    const history = useHistory();
    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues: {
            // contacts: [],
            lists: []
        },
        isInitialValid: false,
        validationSchema: object().shape({
            // contacts: array(),
            lists: array().min(1, "Select at least one list")
        }),
        onSubmit: async (values) => {
            try {
                setAddToListsProcessing(true);
                const list_ids = values.lists.map((list) => list.id);
                const contact_ids = selectedContacts !== "all" ? Object.keys(selectedContacts) : undefined;
                const res = await assignToList({
                    contact_ids,
                    list_ids,
                    filters
                });

                dispatch(
                    onUpdateToast({
                        value: `${
                            contact_ids?.length > 1 || selectedContacts === "all" ? "Contacts were" : "Contact was"
                        } sucessfully added to ${pluralize("list", list_ids.length)}`,
                        type: "saved"
                    })
                );
            } catch (err) {
                console.log("ERR >>>>>", err);
            } finally {
                setAddToListsProcessing(false);
            }
        }
    });

    const navToListSettings = () => {
        history.push("/account-settings/lists");
    };

    return (
        <>
            <StyledAlert
                Target={({ onClick }) => (
                    <StyledButton
                        text={"Add To List"}
                        type={StyledButtonTypes.secondary}
                        LeftIconComponent={SelectedPersonsIcon}
                        onClick={onClick}
                    />
                )}
                className={styles["save-view-dialog"]}
                // isOpen={isOpen}
                type={StyledAlertTypes.primary}
                canOutsideClickCancel={true}
                canEscapeKeyCancel={true}
                cancelButtonText={"Cancel"}
                confirmButtonText={"Add"}
                confirmButtonProps={{
                    type: StyledButtonTypes.primary,
                    processing: addToListsProcessing,
                    disabled: !formik.isValid
                }}
                onConfirm={formik.submitForm}
                globalContentProps={{
                    onCancel: () => null,
                    title: "Add To Static List",
                    hideDescription: true
                }}
                leftFooterComponent={
                    <StyledButton
                        type={StyledButtonTypes.primarySimple}
                        text={"Manage Lists"}
                        onClick={navToListSettings}
                    />
                }
            >
                <div className={classNames(styles["main-content-wrapper"], Classes.CONTAINER_INPUTS_ROW_GAP)}>
                    <SelectedContacts selectedContacts={selectedContacts} />
                    <StaticListsDropdown
                        value={formik.values.lists || []}
                        initialValues={formik.initialValues.lists}
                        onChange={(value) => {
                            formik.setFieldValue("lists", value);
                        }}
                    />
                </div>
            </StyledAlert>
        </>
    );
};
