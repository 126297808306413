import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { Dialog } from "@blueprintjs/core";
import classNames from "classnames";
import { FormikConfig } from "formik";
import { Stepper } from "../stepper";
import { StyledButton, StyledButtonTypes } from "../styled-button";
import styles from "./DialogStepper.module.scss";
import { useExtendedFormik } from "../../containers/conversations/components/chat/hooks/useExtendedFormik";
import { ReactComponent as ButtonChevronRightIcon } from "src/mpd-library/icon/assets/ButtonChevronRight.svg";
import { ReactComponent as ButtonChevronLeftIcon } from "src/mpd-library/icon/assets/ButtonChevronLeft.svg";

type DialogStepperProps = {
    title: string;
    renderTarget: ReactNode;
    stepsPanels: Array<ReactNode>;
    formikConfig: FormikConfig<any>;
    validationSchema: any;
    submit: any;
    fieldStepMatch: { [key: string]: number };
    renderFooterMiddleComponent: (props: { formik: any; activeStepIndex: number }) => JSX.Element | null;
    onNextClickCustom?: (activeStaepIndex: number) => void;
};

export const DialogStepper = ({
    title,
    renderTarget,
    stepsPanels,
    fieldStepMatch,
    formikConfig,
    renderFooterMiddleComponent,
    onNextClickCustom,
    setFormik,
    submit
}: DialogStepperProps) => {
    const [activeStepIndex, setActiveStepIndex] = useState<number>(0);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [submitProcessing, setSubmitProcessing] = useState<boolean>(false);

    const { formik, stepsWithErrors } = useExtendedFormik({
        formikConfig: {
            ...formikConfig,
            validationSchema: formikConfig?.validationSchema?.[activeStepIndex],
            onSubmit: async (values, actions) => {
                setSubmitProcessing(true);
                try {
                    if (activeStepIndex === stepsPanels.length - 1) {
                        await submit(values);
                        setIsOpen(false);
                        actions.resetForm();
                        setActiveStepIndex(0);
                    } else {
                        onNextClick();
                        actions.setTouched({});
                        actions.setSubmitting(false);
                    }
                } finally {
                    setSubmitProcessing(false);
                }
            }
        },
        errorsFieldsMatchObject: fieldStepMatch
    });

    console.log("FFFFORMIK >>>>", formik);

    // useEffect(() => {
    //     console.log("WE SET F FORMIK >>>>", formik);
    //     if (formik && setFormik) {
    //         setFormik(formik);
    //     }
    // }, [formik]);

    useEffect(() => {
        formik.validateForm();
    }, [activeStepIndex]);

    const StepPanel = useMemo(() => {
        const Panel = stepsPanels[activeStepIndex].component;
        return <Panel formik={formik} />;
    }, [formik, activeStepIndex]);

    const onNextClick = useCallback(async () => {
        await onNextClickCustom?.(activeStepIndex, formik);
        setActiveStepIndex(activeStepIndex + 1);
    }, [activeStepIndex, formik]);

    const onBackClick = useCallback(() => {
        setActiveStepIndex(activeStepIndex - 1);
    }, [activeStepIndex]);

    const onModalOpen = () => {
        setIsOpen(true);
    };

    const onCancelClick = () => {
        setIsOpen(false);
        setActiveStepIndex(0);
        formik.resetForm();
    };

    const isLastStep = useMemo(() => stepsPanels.length - 1 === activeStepIndex, [activeStepIndex]);

    return (
        <>
            {!!renderTarget && renderTarget(onModalOpen)}
            <Dialog isOpen={isOpen} usePortal className={styles["root"]} portalClassName={styles["portal"]}>
                {/* <div style={{ width: "100%", height: "100%", background: "red" }} /> */}
                <div className={styles["header"]}>
                    <div className={styles["header__title"]}>{title || ""}</div>
                    <Stepper
                        type="horizontal"
                        loading={false}
                        onItemClick={() => null}
                        isMobileSize={false}
                        steps={stepsPanels}
                        stepsWithErrors={stepsWithErrors}
                        activeStepIndex={activeStepIndex}
                        className={styles["dialog-stepper"]}
                    />
                    <div className={classNames(styles["header__title"], styles["header__title_steps"])}>{`Step ${
                        activeStepIndex + 1
                    } of ${stepsPanels.length}`}</div>
                </div>
                <div className={styles["step-panel"]}>
                    <h3 className={styles["step-panel__title"]}>
                        {stepsPanels[activeStepIndex].title || stepsPanels[activeStepIndex].getTitle(formik)}
                    </h3>
                    <h6 className={styles["step-panel__subtitle"]}>{stepsPanels[activeStepIndex].subtitle}</h6>
                    {StepPanel}
                </div>
                <div className={styles["footer"]}>
                    <div className={styles["footer__left-wrapper"]}>
                        {activeStepIndex > 0 && (
                            <StyledButton
                                text="Back"
                                LeftIconComponent={ButtonChevronLeftIcon}
                                type={StyledButtonTypes.secondary}
                                onClick={onBackClick}
                            />
                        )}
                        <StyledButton text="Cancel" onClick={onCancelClick} />
                    </div>
                    {renderFooterMiddleComponent?.({ formik, activeStepIndex })}
                    <StyledButton
                        text={isLastStep ? "Finish" : "Next"}
                        type={StyledButtonTypes.primary}
                        onClick={formik.handleSubmit}
                        disabled={!formik.isValid}
                        IconComponent={!isLastStep && ButtonChevronRightIcon}
                        processing={submitProcessing}
                    />
                </div>
            </Dialog>
        </>
    );
};
