export const getDisplayedName = (user) => {
    const { first_name, last_name, contacts } = user || {};
    const { phone_number, email } = contacts || {};
    if (first_name) {
        return `${user?.first_name} ${last_name || ""}`;
    } else if (phone_number) {
        return phone_number;
    } else if (email) {
        return email;
    } else {
        return "Unnamed user";
    }
};
