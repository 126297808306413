import { FormikValues } from "formik";
import { useMemo } from "react";
import { ConversationsRoutingSettings } from "src/components/conversations-routing-settings/conversations-routing-settings";
import styles from "./styles.module.scss";

type CustomizeProps = {
    formik: FormikValues;
};

export const Customize = ({ formik }: CustomizeProps) => {
    const { setFieldValue, values, initialValues }: FormikValues = formik;

    const onSetSettings = (value: string, key: string) => {
        setFieldValue(key, value);
    };

    // formik.getFieldMeta(fieldName).initialValue;

    const routingSettings = useMemo(() => {
        return { tags: values.tags, admin: values.admin, queue: values.queue };
    }, [values]);

    return (
        <div className={styles["root"]}>
            <ConversationsRoutingSettings
                setSelectedSettings={onSetSettings}
                routingSettings={routingSettings}
                routingSettingsInitial={initialValues}
            />
        </div>
    );
};
