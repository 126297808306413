import { useContext, useMemo, useState } from "react";
import { Position } from "@blueprintjs/core";
import classNames from "classnames";
import pluralize from "pluralize";
import isEmpty from "lodash/isEmpty";
import {
    SearchInput,
    SearchInputClasses,
    StyledAlert,
    StyledAlertTypes,
    StyledButton,
    StyledButtonTypes
} from "../../../../../components";
import { Dropdown, Option } from "../../../../../mpd-library/dropdown/dropdown";
import { DropdownTargetClasses } from "src/mpd-library/dropdown/components/target/constants";
import styles from "./ContactsListHeader.module.scss";
import { SORT_BY_VALUE } from "./constants";
import { AddToStaticListDialog } from "../add-to-static-list-dialog/AddToStaticListDialog";
import { AddTagsDialog } from "../add-tags-dialog/AddTagsDialog";
import { ReactComponent as VerticalTripleLineIcon } from "src/mpd-library/icon/assets/vertical-triple-line.svg";
import { ListSort } from "../../../../../components/list-sort/ListSort";
import { ContactsContext } from "../../context";
import { TSelectItemTypes } from "src/mpd-library/select-item";
import { DndContent } from "../../../../../mpd-library/dropdown/components/dnd-content/DndContent";
import { getContactsSettings, updateContactsSettings } from "../../../../../requests/contactsRequests";
import { ICONS_TYPE_MATCH } from "../../../../../components/contacts-filters/constants";
import { PageLoadingSpinner } from "../../../../../components/page-loading-spinner/page-loading-spinner";

export const ContactsSearchSort = ({
    selectedContacts,
    searchValue,
    setSearchValue,
    count,
    onSortChange,
    optionsForSelectedContacts,
    onDeleteContactFromList,
    filters,
    setContactsListForceReload,
    onSetContactsSettings,
    contactsSettings
}) => {
    const [updateContactsSettingsProcessing, setUpdateContactsSettingsProcessing] = useState<boolean>(false);
    const { setDeletableContacts } = useContext(ContactsContext);

    const selectedCounter = useMemo(() => {
        if (selectedContacts === "all") {
            return count;
        } else if (!isEmpty(selectedContacts)) {
            return Object.keys(selectedContacts).length;
        } else return null;
    }, [selectedContacts]);

    const { add_to_list, tags, remove_from_list, more } = optionsForSelectedContacts || {};

    const removeSelectedContactsFromList = async () => {
        await new Promise((resolve) => {
            setTimeout(() => {
                onDeleteContactFromList(Object.keys(selectedContacts));
                resolve(true);
            }, 3000);
        });
    };

    const onDeleteOptionClick = () => {
        const contacts_ids = selectedContacts === "all" ? selectedContacts : Object.keys(selectedContacts);
        setDeletableContacts(contacts_ids);
    };

    const onChangeContactsSettings = async (options) => {
        try {
            setUpdateContactsSettingsProcessing(true);
            const res = await updateContactsSettings({
                list_fields: options.map((fieldMeta) => {
                    return {
                        field: fieldMeta.field,
                        enabled: fieldMeta.enabled
                    };
                })
            });
            onSetContactsSettings(
                res.data.list_fields.map((field) => {
                    return {
                        ...field,
                        LeftIconComponent: ICONS_TYPE_MATCH[field.type],
                        disabled: field.field === "name"
                    };
                })
            );
        } finally {
            setUpdateContactsSettingsProcessing(false);
        }
    };

    return (
        <div className={styles["second-line-block"]}>
            <SearchInput
                placeholder={"Search contacts..."}
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                className={SearchInputClasses.list}
            />
            <div className={styles["counter"]}>
                {!selectedCounter && `${new Intl.NumberFormat("en-US").format(count)} ${pluralize("Contact", count)}`}
                {selectedCounter && (
                    <>
                        <span className={styles["counter-value"]}>{`${selectedCounter} selected`}</span>
                        {add_to_list && (
                            <AddToStaticListDialog
                                selectedContacts={selectedContacts}
                                filters={filters}
                                setContactsListForceReload={setContactsListForceReload}
                            />
                        )}
                        {tags && (
                            <AddTagsDialog
                                selectedContacts={selectedContacts}
                                filters={filters}
                                setContactsListForceReload={setContactsListForceReload}
                            />
                        )}
                        {remove_from_list && (
                            <StyledAlert
                                Target={({ onClick }) => (
                                    <StyledButton
                                        type={StyledButtonTypes.secondary}
                                        onClick={onClick}
                                        text={"Remove from List"}
                                    />
                                )}
                                type={StyledAlertTypes.warning}
                                globalContentProps={{
                                    title: `Remove ${pluralize("contact", count)}?`,
                                    description: `${selectedCounter > 1 ? selectedCounter : ""} ${pluralize(
                                        "Contact",
                                        selectedCounter
                                    )} will be removed from this list`
                                }}
                                confirmButtonProps={{ type: StyledButtonTypes.delete }}
                                confirmButtonText="Remove"
                                onConfirm={removeSelectedContactsFromList}
                            />
                        )}
                        {more && (
                            <Dropdown
                                id={"MOREDROPDOWN"}
                                staticOptions={[
                                    {
                                        label: "Delete permanently",
                                        value: null,
                                        onClick: onDeleteOptionClick,
                                        type: TSelectItemTypes.delete
                                    }
                                ]}
                                usePortal
                                ArrowIconComponent={VerticalTripleLineIcon}
                                targetClassName={classNames(DropdownTargetClasses.SELECT, DropdownTargetClasses.MORE)}
                                position={Position.BOTTOM_LEFT}
                                portalStopPropagationEvents={["click"]}
                                contentBlockWidth={220}
                            />
                        )}
                    </>
                )}
            </div>
            <div className={styles["sort-wrapper"]}>
                <Dropdown
                    fetchDataRequest={getContactsSettings}
                    title={"Edit Columns"}
                    targetClassName={DropdownTargetClasses.SELECT}
                    CustomOptionsComponent={(props) => {
                        return (
                            <div className={styles["contacts-settings-dropdown-wrapper"]}>
                                {!!updateContactsSettingsProcessing && (
                                    <div className={styles["spinner-wrapper"]}>
                                        <PageLoadingSpinner />
                                    </div>
                                )}
                                <DndContent {...props} onChange={onChangeContactsSettings} options={contactsSettings} />
                            </div>
                        );
                    }}
                    ArrowIconComponent
                    contentBlockWidth={250}
                    contentBlockHeight={400}
                    fieldNameForId="field"
                    entityKey="list_fields"
                    position={Position.BOTTOM_RIGHT}

                    // usePortal={false}
                />
                <ListSort
                    // sortValue={sortValue}
                    sortByValues={SORT_BY_VALUE}
                    onChange={onSortChange}
                    // sortDirection={sortDirection}
                    // onSortSelect={onSortSelect}
                    // onSetSortDirection={onSetSortDirection}
                />
            </div>
            {/* <div className={styles["sort"]}>
                <Dropdown
                    position={Position.BOTTOM_RIGHT}
                    contentBlockWidth={220}
                    targetClassName={classNames(DropdownTargetClasses.SELECT, styles["sort-dropdown"])}
                    selectedValue={sortValue}
                    staticOptions={SORT_BY_VALUE}
                    onStaticOptionClick={onSortSelect}
                    leftLabel={"Sort By"}
                    ArrowIconComponent
                />
                <StyledButton
                    onClick={onSetSortDirection}
                    className={classNames(styles["sort-direction-button"], sortDirection === "desc" && "bp4-active")}
                    LeftIconComponent={RotationArrowIcon}
                    type={StyledButtonTypes.secondary}
                />
            </div> */}
        </div>
    );
};
