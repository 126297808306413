import { FIELDS_SHOULD_BE_MAPPED } from "./constants";

export const calculateUnmapped = (mapped_fields) => {
    let mappedCounter = 0;
    const unmappedFieldsNames = [];

    const reducedFileds = mapped_fields.reduce((acc, field) => {
        if (FIELDS_SHOULD_BE_MAPPED[field.field]) {
            ++mappedCounter;
        }
        return {
            ...acc,
            [field.field]: field
        };
    }, {});

    Object.keys(FIELDS_SHOULD_BE_MAPPED).forEach((fieldName) => {
        if (!reducedFileds[fieldName]) {
            unmappedFieldsNames.push(fieldName);
        }
    });

    const unmappedCounter = Object.keys(FIELDS_SHOULD_BE_MAPPED).length - mappedCounter;

    return { unmappedCounter, unmappedFieldsNames };
};
